import styled from '@emotion/styled'
import { cx, css } from '@emotion/css'

export const PrimaryButton = css`
    height:48px;
    border-radius:80px;
    border:none;
    background-color:var(--color-fill-interactive-button-primary-default);
    color: var(--color-text-primary-inverse);
    font-size: var(--font-size-b2);
    font-style: normal;
    font-weight: 600;
    line-height: var(--font-line-height-b2, 24px);
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    gap:8px;
    flex-shrink: 0;
    &[disabled]{
       background-color: var(--color-fill-disabled);
       color: var(--color-text-disabled);
       cursor:auto;
    }
`

export  const SecondaryButton = css`
    height:48px;
    border-radius: 80px;
    border: 1px solid var(--color-stroke-container-primary);
    background: var(--color-fill-interactive-button-secondary-default);
    color: var(--color-text-primary);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    display:flex;
    align-items:center;
    justify-content:center;
    &[disabled]{
       cursor:auto;
    }
`

export const Page = styled.div`
    padding: 16px;
    // background: #FFF;
    background: var(--color-fill-section-tertiary);
    position:relative;
    overflow-y:${props => props.showModal ? 'hidden' : 'auto'};
    overflow-x:hidden;
    // height:100vh;
    margin-top: env(safe-area-inset-top);
    margin-right: env(safe-area-inset-right);
    margin-bottom: env(safe-area-inset-bottom);
    margin-left: env(safe-area-inset-left);
    width: 100vw;
    height: calc(100dvh - env(safe-area-inset-bottom));
    @media(min-width: 430px){
        border-radius: 40px;
        border: 1px solid var(--Neutrals---color-grayscale-030);
        width: 430px;
        margin: 20px auto;
        height: calc(100vh - 40px);
    }
`

export const AuthPage = css`
    background: #FFF !important;
`

export const TaskContainer = styled.div`
    flex:1;
    border-radius: 32px;
    background: var(--background-background-a-white);
    margin-left: -16px;
    width:calc(100% + 32px);
    padding:16px;
    // overflow:auto;
`

export const Overlay = css`
    width:inherit;
    height:inherit;
    background: var(--color-fill-overlay);
    z-index:1000;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media (min-width: 430px) {
        border-radius: 40px;
    }
`

export const Modal = css`
    border-radius: 16px;
    background: #FFF;
    width: calc(100% - 80px);
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    z-index:1001;
    display:flex;
    flex-direction:column;
    align-items:center;
    // justify-content:center;
    padding:20px;
`

export const InputGroup = styled.div`
    margin-bottom:25px;
    width:100%;
`

export const Label = styled.label`
    color: var(--color-text-primary);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom:8px;
    display:block;
`

export const InputContainer = styled.div`
    width:100%;
    height:48px;
    border-radius: 8px;
    border: 1px solid var(--color-stroke-interactive-input-field-default);
    background: var(--color-fill-interactive-input-field-default);
    input{
        width:100%;
        border:none;
        height:100%;
        background:transparent;
        padding-left:12px;
        font-size:16px;
    }
`

export const ShortInstructions = css`
    &>div{
        display:flex;
        align-items:center;
        gap:8px;
    }
`

export const FocusTag = styled.span`
    border-radius: var(--radius-interactive-circular, 80px);
    background: var(--color-fill-container-brand, rgba(158, 0, 126, 0.08));
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-text-interactive-brand-default, #9E007E);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    width:fit-content;
    padding: 2px 12px;
`

export const TextColorGreen = css`
    color: var(--color-text-success);
`

export const TextColorRed = css`
    color: var(--color-text-error);
`

export const TextColorOrange = css`
    color: var(--color-text-warning);
`

export const TextColorBlue = css`
    color: var(--color-text-info);
`