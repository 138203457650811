//audio
import question5Audio from "./question_assets/audio/PTEA_16-LS-REPT_CM6_50_B2_AI.mp3"
import question6Audio from "./question_assets/audio/RM_PTEA_16-LS-REPT_CM3_25_B2_AUIT.mp3"
import question7Audio from "./question_assets/audio/PTEA_16-LS-REPT_CM7_37_B1_FINAL_AUIT.wav"
import question8Audio from "./question_assets/audio/PTEA_16-LS-REPT_CM6_51_C1_AI.mp3"

import question13Audio from "./question_assets/audio/PTEA_20-LS-PRES_CM7_6_B1_FINAL_AUIT.mp3"
import question14Audio from "./question_assets/audio/RM_PTEA_20-LS-PRES_CM4_1_B2_AI.mp3"
import question15Audio from "./question_assets/audio/RM_PTEA_20-LS-PRES_CM4_3_B2_AUIT.mp3"
import question16Audio from "./question_assets/audio/RM_PTEA_20-LS-PRES_CM4_13_C1_AI.mp3"

import question17Audio from "./question_assets/audio/PTEA_21-LS-SAQS_CM7_34_B1_AI.mp3"
import question18Audio from "./question_assets/audio/PTEA_21-LS-SAQS_CM7_11_B1_FINAL_AUIT.mp3"
import question19Audio from "./question_assets/audio/PTEA_21-LS-SAQS_CM7_14_B2_FINAL_AUIT.mp3"
import question20Audio from "./question_assets/audio/PTEA_21-LS-SAQS_CM7_27_C1_AI.mp3"

//images
import question9Image from "./question_assets/images/RM_PTEA_19-SS-DESC_CM4_1_B2_AW-1.PNG"
import question10Image from "./question_assets/images/PTEA_19-SS-DESC_CM7_28_B1_Final_AW-1.jpg"
import question11Image from "./question_assets/images/RM_PTEA_19-SS-DESC_CM3_16_B2_AW.PNG"
import question12Image from "./question_assets/images/Habits.png"

import question13Image from "./question_assets/images/PTEA_20-LS-PRES_CM7_6_B1_FINAL_PH.jpg"
import question14Image from "./question_assets/images/RM_PTEA_20-LS-PRES_CM4_1_B2_PH.jpg"
import question15Image from "./question_assets/images/RM_PTEA_20-LS-PRES_CM4_3_B2_PH.jpg"

//content objectives
import { content_objectives } from "./content_objectives"

export const questions = [
    {
        "id": 1,
        "topic": "Architecture",
        "level": "B1",
        "type": "read_aloud",
        "question": {
            "text": "Architecture is shaped by factors like culture, climate, and practical needs. Climate impacts design by determining if buildings should be warm or cool. Private homes also mirror society; for example, architects consider that more people work from home today, and family sizes are generally decreasing when designing new homes."
        },
    },
    {
        "id": 2,
        "topic": "Climate change",
        "level": "C1",
        "type": "read_aloud",
        "question": {
            "text": "To counter climate change effects on forests, major steps are planned. In some areas, tree species better suited to the new climate will replace those struggling with warmer temperatures. Some forests may also be moved to regions with climates that can better support their growth and survival."
        }
    },
    {
        "id": 3,
        "topic": "Sports",
        "level": "B2",
        "type": "read_aloud",
        "question": {
            "text": "Sponsorship plays a key role in elite sports. Popular events draw large global audiences and media attention, so companies sponsor athletes or teams to grow brand awareness. This financial support helps teams improve, though the true business value of sponsorships has sometimes been questioned."
        }
    },
    {
        "id": 4,
        "topic": "Transport",
        "level": "B1",
        "type": "read_aloud",
        "question": {
            "text": "All methods of modern transport come with a cost to the environment. It is necessary to compare the energy needs of the different vehicles to assess the impact they have on climate change. This can help us make the right choice for the future of travel."
        }
    },
    {
        "id": 5,
        "topic": "Education",
        "level": "B2",
        "type": "repeat_sentence",
        "question": {
            audio: question5Audio,
            "text": "Using multicultural literature in the classroom is important as schools have become more diverse."
        }
    },
    {
        "id": 6,
        "topic": "Science",
        "level": "B2",
        "type": "repeat_sentence",
        "question": {
            audio: question6Audio,
            "text": "Medical scientists have carried out extensive research on the nervous system."
        }
    },
    {
        "id": 7,
        "topic": "Habits",
        "level": "B1",
        "type": "repeat_sentence",
        "question": {
            audio: question7Audio,
            "text": "Consumers are cutting back on the amount of money they spend on furniture."
        }
    },
    {
        "id": 8,
        "topic": "Archaeology",
        "level": "C1",
        "type": "repeat_sentence",
        "question": {
            audio: question8Audio,
            "text": "During recent excavations, archaeologists have unearthed the remains of an ancient temple."
        }
    },
    {
        "id": 9,
        "topic": "Travel",
        "level": "B2",
        "type": "describe_image",
        "question": {
            image: question9Image
        },
        "co_threshold": 0.1
    },
    {
        "id": 10,
        "topic": "Population",
        "level": "B1",
        "type": "describe_image",
        "question": {
            image: question10Image
        },
        "content_objectives": content_objectives[10],
        "co_threshold": 0.3
    },
    {
        "id": 11,
        "topic": "Environment",
        "level": "B2",
        "type": "describe_image",
        "question": {
            image: question11Image
        },
        "co_threshold": 0.6
    },
    {
        "id": 12,
        "topic": "Habits",
        "level": "C1",
        "type": "describe_image",
        "question": {
            image: question12Image
        },
        "co_threshold": 0.6
    },
    {
        "id": 13,
        "topic": "Health",
        "level": "B1",
        "type": "retell_lecture",
        "question": {
            audio: question13Audio,
            image: question13Image
        },
        "co_threshold": 0.8
    },
    {
        "id": 14,
        "topic": "Food",
        "level": "B2",
        "type": "retell_lecture",
        "question": {
            audio: question14Audio,
            image: question14Image
        },
        "co_threshold": 0.6
    },
    {
        "id": 15,
        "topic": "Astronomy & Physics",
        "level": "B2",
        "type": "retell_lecture",
        "question": {
            audio: question15Audio,
            image: question15Image
        },
        "co_threshold": 0.3
    },
    {
        "id": 16,
        "topic": "Education",
        "level": "C1",
        "type": "retell_lecture",
        "question": {
            audio: question16Audio
        },
        "co_threshold": 0.3
    },
    {
        "id": 17,
        "topic": "Competition",
        "level": "B1",
        "type": "short_question",
        "question": {
            audio: question17Audio,
        },
        "answer": "cheat"
    },
    {
        "id": 18,
        "topic": "English Language",
        "level": "B1",
        "type": "short_question",
        "question": {
            audio: question18Audio
        },
        "answer": "definition"
    },
    {
        "id": 19,
        "topic": "Subject",
        "level": "B2",
        "type": "short_question",
        "question": {
            audio: question19Audio
        },
        "answer": "chemistry"
    },
    {
        "id": 20,
        "topic": "Specialism",
        "level": "C1",
        "type": "short_question",
        "question": {
            audio: question20Audio
        },
        "answer": "jargon"
    }
]