import {useState, useEffect, useRef} from 'react'
import { cx, css } from '@emotion/css'
import styled from '@emotion/styled'
import ListenTimer from "./ListenTimer.jsx"
import { PrimaryButton} from "../../global_styles/global_styles.js"
import mixpanel from "mixpanel-browser";

import play from "../../images/play.svg"
import listenWhite from "../../images/listen_white.svg"

function RetellLecture({questionData}) {

    const [totalTime, setTotalTime] = useState(0)
    const [currentTime, setCurrentTime] = useState(0)
    const [playHidden, setPlayHidden] = useState(false)
    const [audioPlaying, setAudioPlaying] = useState(false)
    const [resetTimer, setResetTimer] = useState(false)

    const audioRef = useRef(null)

    const handlePlayAudio = () => {
        audioRef.current.play()
        setAudioPlaying(true)
        setPlayHidden(true)
    }

    const handlePauseAudio = () => {
        audioRef.current.pause()
        setAudioPlaying(false)
    }

    const handleEnded = () => {
        // setAudioPlaying(false)
        // setCurrentTime(0);
    }

    const handleGetDuration = () => {
        setTotalTime(audioRef.current.duration)
    }

    const handleTimeUpdate = () => {
        setCurrentTime(audioRef.current.currentTime);
    }

    const resetTimerCallback = () => {
        setResetTimer(false)
    }

    const handleTimerEnd = () => {
        setResetTimer(true)
        setAudioPlaying(false)
        setCurrentTime(0);
        setPlayHidden(false)
    }


    return(
        <>
            <ListenImage>
                <ImageContainer>
                    {
                        questionData.type === 'retell_lecture' && playHidden != true ?
                            <button
                                className={cx(PrimaryButton, AltPlacementButton)}
                                onClick={() => {
                                    mixpanel.track('Audio Playback Triggered', {
                                        interactionType: 'Click', // Indicates this was a click interaction
                                    });
                                    handlePlayAudio(); // Calls the original handler
                                }}
                                onTouchStart={() => {
                                    mixpanel.track('Audio Playback Triggered', {
                                        interactionType: 'Touch', // Indicates this was a touch interaction
                                    });
                                    handlePlayAudio(); // Calls the original handler
                                }}
                            >
                                <img src={play} alt="Play audio"/>
                                Play audio
                            </button>

                            : null
                    }
                    {
                        (questionData.question.image && !audioPlaying) || !questionData.question.image ?
                            <ListenWhite src={listenWhite}/>
                            : null
                    }
                    {
                        questionData.question.image ?
                            <DataImage audioPlaying={audioPlaying}>
                                <img src={questionData.question.image}/>
                            </DataImage>
                            :
                            <DataImagePlaceholder/>
                    }
                    {
                        totalTime > 0 ? 
                        <ListenTimer 
                            value={Math.round(totalTime)}
                            audioPlaying={audioPlaying}
                            handleTimerEnd={handleTimerEnd}
                            resetTimer={resetTimer}
                            resetTimerCallback={resetTimerCallback}
                            showPause={true}
                            handlePlayAudio={handlePlayAudio}
                            handlePauseAudio={handlePauseAudio}
                        />
                        : null
                    }
                    
                </ImageContainer>
            </ListenImage>
            <audio 
                ref={audioRef}
                onEnded={handleEnded} 
                onLoadedMetadata={handleGetDuration}
                onTimeUpdate={handleTimeUpdate} 
            >
                <source src={questionData.question.audio} type="audio/mpeg"/>
            </audio>
        </>
    )
}

export default RetellLecture


const ListenImage = styled.div`
    width:100%;
    height:300px;
    border-radius: 8px;
    background: var(--color-fill-container-tertiary);
    padding:16px;
    margin-top:40px;
`

const ListenWhite = styled.img`
    position:absolute;
    width: 197px;
    height: 49px;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    z-index:1;
`

const ImageContainer = styled.div`
    width:100%;
    height:233px;
    position:relative;
`

const DataImage = styled.div`
    width:100%;
    height:233px;
    position:relative;
    margin-bottom:8px;
    img{
        width:100%;
        height:233px;
        object-fit:cover;
    }
    &:before{
        display:block;
        content:'';
        width:100%;
        height:100%;
        background-color:black;
        opacity:${props=> props.audioPlaying ? '0%' : '50%'};
        position:absolute;
        top:0;
        left:0;
    }
`

const DataImagePlaceholder = styled.div`
    width:100%;
    height:233px;
    position:relative;
    margin-bottom:8px;
`

const AltPlacementButton = css`
    position:absolute;
    width: 153px;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    z-index:2;
`