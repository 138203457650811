import { useState } from 'react';
import styled from '@emotion/styled'
import { cx, css } from '@emotion/css'
import { PrimaryButton } from '../global_styles/global_styles';
import { useNavigate } from 'react-router-dom';
import { signup } from '../util/signup';
import Alert from "./shared/Alert"

function Signup() {

    const navigate = useNavigate()

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');
    const [pilotUser, setPilotUser] = useState(false)
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [showAlert, setShowAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        setPasswordsMatch(event.target.value === confirmPassword);
    };
    
    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
        setPasswordsMatch(password === event.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        let formData = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: password,
            pilotUser: pilotUser
        }
        signup(formData)
        .then(result => {
            console.log(result)
            // navigate(process.env.PUBLIC_URL + "/signin")
            setAlertMessage({
                title: 'Success',
                message: 'User created'
            })
            setShowAlert(true)
            setFirstName('')
            setLastName('')
            setEmail('')
            setPassword('')
            setConfirmPassword('')
            setPilotUser(false)
            setTimeout(()=>{
              setShowAlert(false)
            },8000)
        })
        .catch(err => console.log(err))
    }

    return (
      <div>
        <h2>Create an account</h2>
        <Form onSubmit={handleSubmit}>
            <InputGroup>
                <label>First name</label>
                <input
                    type="text"
                    placeholder="e.g. John"
                    onChange={(e)=>{setFirstName(e.target.value)}}
                    value={firstName}
                    required
                />
            </InputGroup>
            <InputGroup>
                <label>Last name</label>
                <input
                    type="text"
                    placeholder="e.g. Smith"
                    onChange={(e)=>{setLastName(e.target.value)}}
                    value={lastName}
                    required
                />
            </InputGroup>
            <InputGroup>
                <label>Email</label>
                <input
                    type="email"
                    placeholder="e.g. John-smith@mail.com"
                    onChange={(e)=>{setEmail(e.target.value)}}
                    value={email}
                    required
                />
            </InputGroup>
            <InputGroup>
                <label>Password</label>
                <input
                    type="password"
                    placeholder="Create password"
                    onChange={handlePasswordChange} 
                    value={password}
                    required
                />
            </InputGroup>
            <InputGroup>
                <label>Repeat password</label>
                <input
                    type="password"
                    placeholder="Create password"
                    onChange={handleConfirmPasswordChange} 
                    value={confirmPassword}
                    required
                />
            </InputGroup>
            <div style={{margin: '20px 0'}}>
            <input 
                  type="checkbox" 
                  id="pilot-user-input"
                  name="pilot-user"
                  value={pilotUser}
                  onChange={(event)=>{setPilotUser(event.target.checked)}}
              />
              <label 
                  htmlFor='pilot-user-input'
              >
                  Create pilot user account
              </label>
            </div>
            {!passwordsMatch && <p>Passwords do not match</p>}
            <button className={cx(PrimaryButton, NextButton)}>
                Next
            </button>
            {
              showAlert ? 
              <Alert title={alertMessage.title} message={alertMessage.message}/>
              : null
            }
        </Form>
      </div>
    );
  }
  
  export default Signup;

  const Form = styled.form`
    width:100%
  `

  const InputGroup = styled.div`

  `;

  const NextButton = css`
    width:100%;
  `