export const questions = [
    {
        id: 1,
        question: "I could understand ______________ of the information.",
        type: "radio",
        options: [
            "None",
            "Some",
            "Most / All",
        ],
        moreOptions: [
            "I understood all the information",
            "I'm not sure"
        ]
    },
    {
        id: 2, 
        question: "Select all the areas you didn't understand",
        type: "multiple_choice",
        options:[
            "Content",
            "Pronunciation",
            "Fluency"
        ],
        otherFeedback: true,
        moreOptions: [
            "I'm not sure"
        ]
    },
    {
        id: 3, 
        question: "I found the information _____________.",
        type: "radio",
        options: [
            "Not useful",
            "Somewhat useful",
            "Very useful"
        ],
        moreOptions: [
            "I'm not sure"
        ]
    },
    {
        id: "feedback", 
        question: "Do you have any suggestions to improve the scoring and feedback you received?",
        type: "feedback",
    }
]