import {useState, useEffect, useRef} from 'react'
import { cx, css } from '@emotion/css'
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import {PrimaryButton, TaskContainer, ShortInstructions, SecondaryButton} from "../global_styles/global_styles"
import {getTypeProperName} from "../util/getTypeProperName.js"
import { useSelector, useDispatch } from 'react-redux';
import { saveAudioRecording } from '../util/saveAudioRecording.js'
import { saveAttempt } from '../util/saveAttempt.js'
import { getUserAttempts } from '../util/getUserAttempts.js'
import { setUserAttempts } from '../redux/userAttemptsSlice.js'
import parse from "html-react-parser";
import Timer from './shared/Timer.jsx'
import { useNavigate, Link } from 'react-router-dom';
import Alert from './shared/Alert.jsx'
import ImageModal from './shared/ImageModal.jsx'
import TextTimer from "./shared/TextTimer.jsx"
import { useWebSocket } from '../util/WebSocketProvider'
import { setWebSocketState } from '../redux/webSocketSlice.js'
import { startAttempt } from '../util/startAttempt.js'
import { deleteAttempt } from '../util/deleteAttempt.js'
import mixpanel from "mixpanel-browser";
import { useLoadingTime } from '../util/LoadingTimeProvider';

//images
import micGrey from "../images/microphone_grey.svg"
import micDark from "../images/microphone_dark.svg"
import micWhite from "../images/microphone_white.svg"
import clock from "../images/clock.svg"
import record from "../images/record.svg"
import recordSuccess from "../images/record_success.svg"
import recordError from "../images/record_error.svg"
import recordUnavailable from "../images/record_unavailable.svg"
import warning from "../images/warning.svg"
import info from "../images/info-dark.svg"


function Task({questionData, questionId, typeData, handleShowModal}) {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const webSocket = useWebSocket();
    const { startLoading, endLoading } = useLoadingTime();

    const SAMPLE_RATE = 16000;

    let silenceThreshold = 10;  // Threshold below which we consider the audio silent
    let speechThreshold = 20; //Threshold above which we consider speaking
    let silenceDuration = 5000; //5 seconds
    let minResponseDuration = 10000 //10 seconds

    const userProfile = useSelector(state => state.userProfile)
    const userAttempts = useSelector(state => state.userAttempts)

    const [permission, setPermission] = useState(null);
    const [showAlert, setShowAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState({title: '', message: ''})
    const [shortInstructions, setShortInstructions] = useState(``);
    const [startTaskDisabled, setStartTaskDisabled] = useState(true)
    const [finishTaskDisabled, setFinishTaskDisabled] = useState(true)
    const [timerValue, setTimerValue] = useState(typeData.prepTime ? typeData.prepTime : typeData.recordTime)
    const [resetTimer, setResetTimer] = useState(false)
    const [audioChunks, setAudioChunks] = useState([]);
    const [audio, setAudio] = useState(false);
    const [totalAttempts, setTotalAttempts] = useState(0)
    const [showImageModal, setShowImageModal] = useState(false)
    const [loadingScore, setLoadingScore] = useState(false)
    const [scoreAvailable, setScoreAvailable] = useState(false)
    const [lastSoundTime, setLastSoundTime] = useState(Date.now());
    const [silent, setSilent] = useState(false) //Shows a message for periods of silence lasting 5 seeconds
    const [wasSpeaking, setWasSpeaking] = useState(false) //used to track if the user spoke for at least 10 seconds
    const [wasSilentForTimer, setWasSilentForTimer] = useState(true);  // Tracks if silence persisted
    const [recordingTimeEnded, setRecordingTimeEnded] = useState(false)
    const [recordingSaved, setRecordingSaved] = useState(null)
    const [attemptAborted, setAttemptAborted] = useState(false)

    const recordingStatus = useRef("inactive")
    const attemptId = useRef(null);
    const feedbackId = useRef(null);

    const audioContextRef = useRef(null);
    const audioRecorder = useRef(null);
    const analyserRef = useRef(null);
    const audioInputRef = useRef(null);
    const stream = useRef(null);
    const silenceStartTimeRef = useRef(null);  // To track when silence starts
    const speechStartTimeRef = useRef(null);


    const getShortInstructions = () => {
        let html = ``
        if(recordingStatus.current === 'inactive'){
            html = `<div className=${css(ShortInstructions)}>
                        <div>
                            <img src="${clock}"/>
                            ${typeData.shortInstructions.prep.main}
                        </div>
                        ${typeData.shortInstructions.prep.sub ? typeData.shortInstructions.prep.sub : ''}
                    </div>`
        }else if(recordingStatus.current === "recording"){

            html = `<div className=${css(ShortInstructions)}>
                        <div>
                            <img src="${clock}"/>
                            ${typeData.shortInstructions.record.main}
                        </div>
                        ${typeData.shortInstructions.record.sub ? typeData.shortInstructions.record.sub : ''}
                    </div>`
        }
        setShortInstructions(html)
    }

    const getMicrophonePermission = async () => {
        navigator.mediaDevices.getUserMedia({ audio: true })
        .then((mediaStream) => {

            // const audioTrack = mediaStream.getAudioTracks()[0];
            // const capabilities = audioTrack.getCapabilities();
            // const sampleRate = capabilities.sampleRate;
            // console.log("Sample Rate:", sampleRate);

            setPermission(true);
            stream.current = mediaStream
        })
        .catch((error) => {
            setAlertMessage({
                title: 'Is your microphone working?',
                message: `Try checking the audio settings on your browser`
            })
            setStartTaskDisabled(true)
            setShowAlert(true)
            setPermission(false);
        });
    };

    const processAudio = (event) => {
        const inputBuffer = event.inputBuffer.getChannelData(0);

        //streaming endpoint
        const PCM16iSamples = new Int16Array(inputBuffer.length);

        for (let i = 0; i < inputBuffer.length; i++) {
            let val = Math.floor(32767 * inputBuffer[i]);
            val = Math.min(32767, val);
            val = Math.max(-32768, val);

            PCM16iSamples[i] = val;
        }
        if(webSocket && webSocket.readyState === WebSocket.OPEN){
            webSocket.send(PCM16iSamples, { binary: true})
        }

        //nonstreaming endpoint and save file recording to DO
        setAudioChunks(prev=>{
            return [...prev, new Float32Array(inputBuffer) ]
        })
    }

    // const detectSilence = () => {
    //     if(analyserRef.current){
    //         let bufferLength = analyserRef.current.frequencyBinCount;
    //         let dataArray = new Uint8Array(bufferLength);
    //         analyserRef.current.getByteFrequencyData(dataArray);

    //         let total = 0;
    //         for (let i = 0; i < bufferLength; i++) {
    //         total += dataArray[i];
    //         }
    //         let averageVolume = total / bufferLength;

    //         // If the volume is below the threshold, consider it silence
    //         if (averageVolume < silenceThreshold) {
    //         // If silenceStartTime is not set, this is the start of the silence
    //         if (!silenceStartTimeRef.current) {
    //             silenceStartTimeRef.current = Date.now(); // Set the silence start time
    //         }

    //         let currentTime = Date.now();
    //         // Check if silence has lasted for at least `silenceDuration` (5 seconds)
    //         if (currentTime - silenceStartTimeRef.current >= silenceDuration) {
    //             console.log("Silence detected for 5 seconds");
    //             setSilent(true) //Mark as silence for at least 5 seconds
    //             silenceStartTimeRef.current = null;  // Reset the silence timer after logging
    //         }
    //         } else {
    //         // Reset the silence timer when sound is detected
    //         setLastSoundTime(Date.now());
    //         setSilent(false)// Mark as no longer silent after 5 seconds
    //         setWasSilentForTimer(false); // Mark as not silent during the timer
    //         silenceStartTimeRef.current = null;  // Reset the silence start time
    //         }
    //     }
    // }

    const detectSilenceAndSpeech = () => {
        if (analyserRef.current) {
            let bufferLength = analyserRef.current.frequencyBinCount;
            let dataArray = new Uint8Array(bufferLength);
            analyserRef.current.getByteFrequencyData(dataArray);
    
            let total = 0;
            for (let i = 0; i < bufferLength; i++) {
                total += dataArray[i];
            }
            let averageVolume = total / bufferLength;
    
            let currentTime = Date.now();
    
            // Check if the volume is below the silence threshold
            if (averageVolume < silenceThreshold) {
                if (!silenceStartTimeRef.current) {
                    silenceStartTimeRef.current = currentTime; // Start of silence
                }
    
                // Check if silence has lasted for at least `silenceDuration` (5 seconds)
                if (currentTime - silenceStartTimeRef.current >= silenceDuration) {
                    // console.log("Silence detected for 5 seconds");
                    setSilent(true); // Mark as silence
                    silenceStartTimeRef.current = null; // Reset silence timer
                    speechStartTimeRef.current = null; // Reset speech timer
                }
            } else {
                // Reset the silence timer when sound is detected
                silenceStartTimeRef.current = null;
                setSilent(false)
    
                // Check if the volume is above the speech threshold
                if (averageVolume > speechThreshold) {
                    if (!speechStartTimeRef.current) {
                        speechStartTimeRef.current = currentTime; // Start of speech
                        setWasSilentForTimer(false); // Mark as not silent during the timer
                    }
    
                    // Check if speech has lasted for at least `minResponseDuration`
                    if (currentTime - speechStartTimeRef.current >= minResponseDuration) {
                        // console.log("Speech detected for at least 10 seconds");
                        setWasSpeaking(true); // Mark as speaking
                        setSilent(false); // Not silent during speech
                        silenceStartTimeRef.current = null; // Reset silence timer
                    }
                } else {
                    // Reset the speech timer when sound is not loud enough
                    speechStartTimeRef.current = null;
                }
            }
        }
    };
    

    const startRealTimeSpeechRecognition = async () => {
        const bufferSize = 4096;
        let audioContext = new AudioContext({ sampleRate: SAMPLE_RATE });
        // console.log("Created new AudioContext:", audioContext);
        let audioInput = audioContext.createMediaStreamSource(stream.current);
        

        const newAudioRecorder = audioContext.createScriptProcessor(
            bufferSize,
            1,
            1
        );
        newAudioRecorder.onaudioprocess = (e) => {
            processAudio(e)
            detectSilenceAndSpeech()
        }
        audioRecorder.current = newAudioRecorder

        audioInput
            .connect(newAudioRecorder)
            .connect(audioContext.destination);

        audioContextRef.current = audioContext

        //Silence detection
        let analyser = audioContext.createAnalyser();
        audioInput.connect(analyser);
        analyser.fftSize = 256;

        analyserRef.current = analyser;
        audioInputRef.current = audioInput;
    }
   
    const handleStartRecording = async () => {
        if(!permission){
            getMicrophonePermission()
        }else{
            startRecording()
        }
    }

    const startRecording = async () => {
        //handleTimer
        setTimerValue(typeData.recordTime)
        setResetTimer(true)
        //start recording status
        recordingStatus.current = "recording"
        //start an attempt and feedback record in the database
        startAttempt({questionId: questionId})
        .then(response=>response.json())
        .then(data=>{
            attemptId.current = data.attemptId
            feedbackId.current = data.feedbackId
        })

        //create new AudioContext
        startRealTimeSpeechRecognition()

        // //start streaming via websocket
        // if(questionData.question.text){
        //     webSocket?.send(JSON.stringify({startStreaming: true, typeData: typeData, referenceText: questionData.question.text}), { binary: false});
        // }
        // //send reference text for question type short_question seems to break the speech to text, not sending for now
        // // else if(questionData.answer){
        // //     webSocket?.send(JSON.stringify({startStreaming: true, typeData: typeData, referenceText: questionData.answer}), { binary: false});
        // // }
        // else{
        //     webSocket?.send(JSON.stringify({startStreaming: true, typeData: typeData,}), { binary: false});
        // }

        if(showImageModal){
            toggleImageModal()
        }
    };

    const handleStopRecording = async () => {

        //stop recording status
        recordingStatus.current = "inactive"
        audioContextRef.current.close();
        audioInputRef.current.disconnect();
        audioRecorder.current.disconnect();

        setAudio(true) //for UI states

        //as long as the user wasn't silent for the duration of the recording then proceed with saving/scoring
        if(wasSilentForTimer){
            abortStreaming()
        }else{
            const flatBuffer = flattenAudioChunks(audioChunks);
            const numberOfSamples = flatBuffer.length;
            const durationInSeconds = numberOfSamples / SAMPLE_RATE;
            const durationInMilliseconds = (flatBuffer.length / SAMPLE_RATE) * 1000;
            // console.log(`Duration of the WAV file: ${durationInSeconds} seconds`);
            //For Read Aloud, Describe Image, Retell Lecture  - they are not scored in the real test if they are under 10 seconds.  Is it possible for us to add in a pop up if under 10 seconds – saying “Your response was too short to be scored and it needs to be over 10 seconds – Please try again”. Copy to be confirmed. 
            if(typeData.type === 'read_aloud' || typeData.type === 'describe_image' || typeData.type === "retell_lecture"){
                if(durationInMilliseconds <= minResponseDuration || !wasSpeaking){
                    // console.log('recording under 10 seconds long');
                    abortStreaming()
                }
                else{
                    handleSaveRecording(flatBuffer)
                }
            }else{
                handleSaveRecording(flatBuffer)
            }
        }
    };

    //helper functions
    const flattenAudioChunks = (chunks) => {
        const length = chunks.reduce((sum, chunk) => sum + chunk.length, 0);
        const result = new Float32Array(length);
        let offset = 0;
    
        chunks.forEach((chunk) => {
            result.set(chunk, offset);
            offset += chunk.length;
        });
    
        return result;
    };

    const encodeWAV = (samples, sampleRate, channels) => {
        const bitDepth = 16; // Define bit depth explicitly (16 bits)
        const durationInSeconds = samples.length / (sampleRate * channels);
    
        // Calculate bitrate
        const bitrate = sampleRate * bitDepth * channels; // Bits per second
        // console.log(`Bitrate: ${(bitrate / 1000).toFixed(2)} kbps`);
        // console.log(`Duration: ${durationInSeconds.toFixed(2)} seconds`);
    
        const buffer = new ArrayBuffer(44 + samples.length * 2);
        const view = new DataView(buffer);
    
        // Write WAV header
        writeString(view, 0, 'RIFF'); // ChunkID
        view.setUint32(4, 36 + samples.length * 2, true); // ChunkSize
        writeString(view, 8, 'WAVE'); // Format
        writeString(view, 12, 'fmt '); // Subchunk1ID
        view.setUint32(16, 16, true); // Subchunk1Size
        view.setUint16(20, 1, true); // AudioFormat (PCM)
        view.setUint16(22, channels, true); // NumChannels
        view.setUint32(24, sampleRate, true); // SampleRate
        view.setUint32(28, sampleRate * channels * 2, true); // ByteRate
        view.setUint16(32, channels * 2, true); // BlockAlign
        view.setUint16(34, bitDepth, true); // BitsPerSample
        writeString(view, 36, 'data'); // Subchunk2ID
        view.setUint32(40, samples.length * 2, true); // Subchunk2Size
    
        // Write PCM data
        let offset = 44;
        for (let i = 0; i < samples.length; i++) {
            const s = Math.max(-1, Math.min(1, samples[i])); // Clamp to [-1, 1]
            view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
            offset += 2;
        }
    
        return new Blob([buffer], { type: 'audio/wav' });
    };
    
    const writeString = (view, offset, string) => {
        for (let i = 0; i < string.length; i++) {
            view.setUint8(offset + i, string.charCodeAt(i));
        }
    };
    //end halper functions


    const handleSaveRecording = (flatBuffer) => {

        //signal end of stream to websocket
        webSocket?.send(JSON.stringify({ endOfStream: true, attemptId: attemptId.current, feedbackId: feedbackId.current, questionId: questionData.id }), { binary: false });
        startLoading(); // LoadingTimeProvider to track score loading accross multiple components

        // Combine audio chunks
        // const flatBuffer = flattenAudioChunks(audioChunks);
        const wavBlob = encodeWAV(flatBuffer, SAMPLE_RATE, 1);

        // Save or upload the WAV file
        const wavFile = new File([wavBlob], `${questionData.id}_${questionData.type}_${questionData.topic.toLowerCase().replaceAll(' ', '_')}/${userProfile.id}_q${questionData.id}_${totalAttempts + 1}.wav`, { type: 'audio/wav' });
        // setAudio(true) //for UI states
        const formData = new FormData();
        formData.append('audio', wavFile);
        saveAudioRecording(formData)
        .then(response=>response.json())
        .then(data=>{
            let attemptData = {
                attemptId: attemptId.current,
                feedbackId: feedbackId.current,
                questionId: questionData.id, 
                audioUrl: data.file
            }
            saveAttempt(attemptData)
            .then(()=>{
                setRecordingSaved(true)
            })
        })
    }

    const disconnectMedia = () => {

        if (stream.current) {
            stream.current.getTracks().forEach(track => track.stop())
            stream.current = null
        }

        if(audioContextRef.current){
            // audioContextRef.current.close();
            audioContextRef.current = null
        }

        if (audioRecorder.current) {
            audioRecorder.current.disconnect();
            audioRecorder.current = null
        }

        if(analyserRef.current){
            analyserRef.current = null;
        }
    }

    const resetTimerCallback = () => {
        setResetTimer(false)
    }

    const handleTimerEnd = () => {
        if(!audio && recordingStatus.current === "inactive"){
            handleStartRecording()
        }else{
            handleStopRecording()
            setRecordingTimeEnded(true)
            // console.log('was silent for the duration of the timer: ' + wasSilentForTimer)
            // console.log('speech detected for at least 10 seconds: ' + wasSpeaking)
            // if(wasSilentForTimer){
            //     setShowAlert(true)
            //     setAlertMessage({
            //         title: 'Your answer was not recorded',
            //         message: `Try checking the audio settings on your browser`
            //     })
            // }
        }
    }

    const toggleImageModal = () => {
        setShowImageModal(!showImageModal)
        handleShowModal()
    }

    const handleViewScore = () => {
        mixpanel.track('View Score - Task', { 'questionId': questionId })
        if(scoreAvailable){
            navigate(process.env.PUBLIC_URL + `/question/${questionId}/score`)
        }else{
            setLoadingScore(true)
        }
    }

    const abortStreaming = () => {
        setAttemptAborted(true)
        //send message to websocket about aborting response etc. 
        webSocket?.send(JSON.stringify({ abort: true, attemptId: attemptId.current, feedbackId: feedbackId.current, questionId: questionData.id }), { binary: false });
        dispatch(setWebSocketState('closed'))
        //delete records of attempt and feedback created from startAttempt()
        let attemptData = {
            attemptId: attemptId.current,
            feedbackId: feedbackId.current,
        }
        deleteAttempt(attemptData)
        .then(()=>{
                attemptId.current = null
                feedbackId.current = null
        })
        setAudioChunks([]);
        disconnectMedia()
    }

    useEffect(()=>{
        if(scoreAvailable && recordingSaved){
            getUserAttempts()
            .then(response=>response.json())
            .then(data=>{
                dispatch(setUserAttempts(data))
                navigate(process.env.PUBLIC_URL + `/question/${questionId}/score`)
            })
        }
    },[scoreAvailable, recordingSaved])

    useEffect(()=>{
        //open node websocket
        // dispatch(setWebSocketState('open'))
        dispatch(setWebSocketState({
            open: 'open',
            typeData: typeData,
            referenceText: questionData.question.text ? questionData.question.text : ""
        }))
        return () => {dispatch(setWebSocketState('closed'))}
    },[])

    useEffect(()=>{
        if(
            typeData.type === 'repeat_sentence' || 
            typeData.type === 'short_question'
        ){
            setTimeout(()=>{ //add timeout to match the timeout in WebSocketProvider that opens the ws connection to ORA
                handleStartRecording()
            },1000)
        }
    },[typeData])

    // This useEffect will trigger startRecording when both conditions are met
    useEffect(() => {
        if (permission && stream.current) {
            startRecording(); // Start recording only when stream and permission are ready
        }
    }, [permission, stream.current]);

    //enable start recording button after 3 seconds
    useEffect(()=>{
        setTimeout(()=>{
            setStartTaskDisabled(false)
        },3000)
    },[])

    //enable finish recording button after 3 seconds
    useEffect(()=>{
        if(!audio && recordingStatus.current === "recording"){
            setTimeout(()=>{
                setFinishTaskDisabled(false)
            },3000)
        }
    },[audio, recordingStatus.current])


    useEffect(()=>{
        if(userAttempts){
            let questionMatch = userAttempts.filter(question => question.questionId === questionData.id) 
            if(questionMatch[0]){
                setTotalAttempts(questionMatch[0].totalAttempts)
            }
        }
    },[userAttempts])

    useEffect(()=>{
        if(typeData.shortInstructions){
            getShortInstructions()
        }
    },[recordingStatus.current])

    //if still recording when user navigates away during a record
    //stop recording on unmount
    //make sure to delete the attempt and feedback records created with startAttempt()
    useEffect(() => {
        // Cleanup function
        return () => {
            if(recordingStatus.current === 'recording'){
                //send message to websocket about aborting response etc. 
                webSocket?.send(JSON.stringify({ abort: true, attemptId: attemptId.current, feedbackId: feedbackId.current, questionId: questionData.id }), { binary: false });
                dispatch(setWebSocketState('closed'))
                //delete records of attempt and feedback created from startAttempt()
                let attemptData = {
                    attemptId: attemptId.current,
                    feedbackId: feedbackId.current,
                }
                deleteAttempt(attemptData)
                .then(()=>{
                        attemptId.current = null
                        feedbackId.current = null
                })
            }

            disconnectMedia()
        };
    }, []);

    //if the user clicked "view score" wait for the webSocket to finish responding before navigating to Score.jsx
    useEffect(()=>{
        const handleWSProviderDone = (event) => {
            if(event.detail.scoreAvailable){
                setScoreAvailable(true)
            }else if(!event.detail.scoreAvailable){
                setAttemptAborted(true)
                // setLoadingScore(false)
                // console.log('set loading score false here?')
                disconnectMedia()
            }
        }
        window.addEventListener("webSocketProviderDone", handleWSProviderDone)
        return () => {
            window.removeEventListener("webSocketProviderDone", handleWSProviderDone);
        };
    },[])
    

    return(
        <>
            {
                loadingScore ? 
                    <TaskContainer>
                        <LoadingContainer>
                            <LoaderAnimation>
                                <span className='first'></span>
                                <span className='middle'></span>
                                <span className='last'></span>
                            </LoaderAnimation>
                            <LoadingTitle>Your answer is being scored</LoadingTitle>
                            <LoadingSubtitle>This may take 20 seconds</LoadingSubtitle>
                        </LoadingContainer>
                    </TaskContainer>
                : 
                <>
                    {
                        showImageModal ? 
                        <ImageModal 
                            image={questionData.question.image}
                            toggleImageModal={toggleImageModal}
                        />
                        : null
                    }
                    {
                        questionData ? <>
                            <h2>{getTypeProperName(questionData.type)}</h2>
                            <TaskContainer>
                            {!audio ? 
                                <>
                                    {
                                        shortInstructions != '' ?
                                        <>{parse(shortInstructions)}</>
                                        : null
                                    }
                                    {
                                        (questionData.type === 'retell_lecture' && recordingStatus.current === 'recording') || 
                                        (questionData.type === 'repeat_sentence' && recordingStatus.current === 'recording') || 
                                        (questionData.type === 'short_question' && recordingStatus.current === 'recording') || 
                                        (questionData.type != 'retell_lecture' && questionData.type != 'repeat_sentence' && questionData.type != 'short_question') ?
                                        <Timer 
                                            value={timerValue} 
                                            handleTimerEnd={handleTimerEnd}
                                            resetTimer={resetTimer}
                                            resetTimerCallback={resetTimerCallback}
                                        />
                                        : null
                                    }
                                    {
                                        (questionData.type === 'retell_lecture' && recordingStatus.current === 'inactive' && !recordingTimeEnded) ?
                                        <TextTimer 
                                            value={timerValue}
                                            handleTimerEnd={handleTimerEnd}
                                            startTimer={true} 
                                        />
                                        : null
                                    }
                                    {
                                        questionData.type === 'read_aloud' ?
                                        <QuestionText>
                                            {questionData.question.text}
                                        </QuestionText>
                                        : null
                                    }
                                    {
                                        questionData.type === 'repeat_sentence' && recordingStatus.current === 'recording' ?
                                        <QuestionRepeat>
                                            <img src={micDark}/>
                                            <p>Repeat the exact sentence</p>
                                        </QuestionRepeat>
                                        : null
                                    }
                                    {
                                        questionData.type === 'describe_image' ?
                                            <QuestionImage
                                                onClick={() => {
                                                    mixpanel.track('Image Interaction', {
                                                        action: 'Zoom Image', // Describes the action
                                                        imageSrc: questionData.question.image, // Logs the source of the image
                                                    });
                                                    toggleImageModal(); // Calls the original function to toggle the modal
                                                }}
                                            >
                                                <div>
                                                    <img src={questionData.question.image} alt="Question Image" />
                                                </div>
                                                <p>Tap/Click the image to zoom</p>
                                            </QuestionImage>

                                            : null
                                    }
                                    {
                                        questionData.type === 'short_question' && recordingStatus.current === 'recording' ?
                                        // <p>Your time to answer</p>
                                        <QuestionRepeat>
                                            <img src={micDark}/>
                                            <p>Your time to answer</p>
                                        </QuestionRepeat>
                                        : null
                                    }
                                    {
                                        recordingStatus.current === 'recording' ? 
                                        silent ? 
                                        <RecordingIndicator type={questionData.type}>
                                            <div className={RecordingAnimation}>
                                                <img src={recordError}/>
                                            </div>
                                            <p className='silent-text'>
                                                <img src={warning}/>
                                                We can't hear you
                                            </p>
                                        </RecordingIndicator>
                                        : 
                                        <RecordingIndicator type={questionData.type}>
                                            <div className={RecordingAnimation}>
                                                <img src={record}/>
                                            </div>
                                            <p>Recording...</p>
                                        </RecordingIndicator>
                                        : null
                                    }
                                    {
                                        showAlert && !recordingTimeEnded ? 
                                        <>
                                        <RecordingIndicator>
                                            <img src={recordError}/>
                                        </RecordingIndicator>
                                        <Alert type="warning" title={alertMessage.title} message={alertMessage.message}/>
                                        </>
                                        : null
                                    }
                                    {
                                        showAlert && recordingTimeEnded && wasSilentForTimer ? 
                                        <>
                                        <RecordingIndicator>
                                            <img src={recordUnavailable}/>
                                        </RecordingIndicator>
                                        <Alert type="info" title={alertMessage.title} message={alertMessage.message}/>
                                        </>
                                        : null
                                    }
                                </>
                                : 
                                <>
                                {
                                    attemptAborted ? 
                                    <AbortMessageContainer>  
                                        <AbortMessage>
                                            <img src={info}/>
                                            <p>Unable to produce a score</p>
                                            {
                                                typeData.type === "repeat_sentence" || typeData.type === "short_question" ? 
                                                <p>Unfortunately, your response was too off topic.</p>
                                                : 
                                                <p>Unfortunately, your response was too off topic or less than 10 seconds.</p>
                                            }
                                            <p>It did not contain enough relevant content for our scoring engine to mark.</p>
                                            <p>Please try again or return to the dashboard.</p>
                                        </AbortMessage>
                                        <AbortButtons>
                                            <button
                                                className={SecondaryButton}
                                                onClick={() => {
                                                    mixpanel.track('Try question again from attempt aborted (unable to score)', {
                                                        action: 'Retry question', // Describes the action
                                                        buttonLabel: 'Retry question', // Logs the label of the button
                                                    });
                                                    window.location.reload();
                                                }}
                                            >
                                               Retry question
                                            </button>
                                            <Link
                                                to="/dashboard"
                                                onClick={() => {
                                                    mixpanel.track('Return to Dashboard from attempt aborted (unable to score)', { 'questionId': questionId })      
                                                }}
                                                className={css(PrimaryButton)}>
                                                Return to Dashboard
                                            </Link>
                                        </AbortButtons>
                                    </AbortMessageContainer> 
                                    : 
                                    <SuccessMessage>
                                        <img src={recordSuccess}/>
                                        <p>Your answer was recorded</p>
                                    </SuccessMessage>
                                }
                                </>
                                
                            }
                            </TaskContainer>
                                {
                                    !audio && recordingStatus.current === "inactive" && !recordingTimeEnded && typeData.type !== 'repeat_sentence' && typeData.type !== 'short_question' ?
                                        <button
                                            className={css(PrimaryButton)}
                                            onClick={() => {
                                                mixpanel.track('Start Recording Button Clicked', {
                                                    buttonState: startTaskDisabled ? 'Disabled' : 'Enabled', // Logs if the button was disabled or enabled
                                                    action: startTaskDisabled ? 'Attempted to start while disabled' : 'Started recording', // Describes the action
                                                });
                                                if (!startTaskDisabled) {
                                                    handleStartRecording(); // Calls the original function only if the button is enabled
                                                }
                                            }}
                                            disabled={startTaskDisabled}
                                        >
                                            <img
                                                src={startTaskDisabled ? micGrey : micWhite}
                                                alt="Microphone Icon"
                                            />
                                            Start recording now
                                        </button>

                                        : null
                                }
                                {
                                    !audio && recordingStatus.current === "recording" ?
                                        <button
                                            className={css(PrimaryButton)}
                                            onClick={() => {
                                                mixpanel.track('Stop Recording Button Clicked', {
                                                    buttonState: finishTaskDisabled ? 'Disabled' : 'Enabled', // Logs if the button was disabled or enabled
                                                    action: finishTaskDisabled ? 'Attempted to stop while disabled' : 'Stopped recording', // Describes the action
                                                });
                                                if (!finishTaskDisabled) {
                                                    handleStopRecording(); // Calls the original function only if the button is enabled
                                                }
                                            }}
                                            disabled={finishTaskDisabled}
                                        >
                                            Finish
                                        </button>

                                        : null
                                }
                                {
                                    audio && recordingStatus.current === "inactive" && !wasSilentForTimer && !attemptAborted ?
                                        <button
                                            className={css(PrimaryButton)}
                                            onClick={() => {
                                                mixpanel.track('View Score Button Clicked', {
                                                    action: 'View Score', // Describes the action
                                                    buttonLabel: 'View score', // Logs the label of the button
                                                });
                                                handleViewScore(); // Calls the original function to handle score viewing
                                            }}
                                        >
                                            View score
                                        </button>

                                        : null
                                }
                                {
                                    !audio && recordingStatus.current === "inactive" && recordingTimeEnded && wasSilentForTimer ? 
                                        <button
                                            className={css(PrimaryButton)}
                                            onClick={() => {
                                                mixpanel.track('Try question again after silence Button Clicked', {
                                                    action: 'Try question again', // Describes the action
                                                    buttonLabel: 'Try question again', // Logs the label of the button
                                                });
                                                window.location.reload();
                                            }}
                                        >
                                            Try question again
                                        </button>

                                        : null
                                }
                            </>
                            : null
                    }
                </>
            }
        </>
    )
}

export default Task

const bounce = keyframes`
    0%, 30% {
        transform: translate(0px);
        background: var(--color-monochrome-15);
    }
    50% {
        transform: translate(0, -8px);
        background: var(--color-brand-primary-50);
    }
    100% {
        transform:translate(0px);
        background: var(--color-monochrome-15);
    }
`

const pulse = keyframes`
    0% {
        transform: scale(0.1);
        background: rgba(21, 73, 139, 0.5);
    }

    70% {
        transform: scale(1);
        background: rgba(21, 73, 139, 0.08);
    }
    100% {
        transform: scale(1);
        background: rgba(21, 73, 139, 0);
    }
`

const LoadingContainer = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    height:100%;
`

const LoaderAnimation = styled.div`
    margin:16px;
    position: relative;
    float:left;
    // width: 62px;
    height: 32px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    gap:6px;
    padding: 0 16px;
    box-sizing:border-box;
    span{
        display:block;
        height:8px;
        width:8px;
        border-radius:8px;
        background: var(--color-monochrome-15);
        animation: ${bounce} 2s infinite ease;
        &.first{
            animation-delay:200ms;
        }
        &.middle{
            animation-delay:500ms;
        }
        &.last{
            animation-delay:800ms;
        }
    }
`

const LoadingTitle = styled.p`
    color: var(--color-text-primary);
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin:0;
    text-align:center;
`

const LoadingSubtitle = styled.p`
    color: var(--Neutrals---color-grayscale-070);
    font-size: 20px
    font-weight: 600;
    line-height: 28px;
    margin-top:16px;
    text-align:center;
`

const QuestionText = styled.p`
    border-radius: 10px;
    background: var(--primary-colours-vet-blue-1-f-0-f-6-ff);
    padding:16px;
    font-size: var(--font-size-b1);
    line-height: var(--font-line-height-b1);
`

const QuestionImage = styled.div`
    width:100%;
    div{
        width:100%;
        cursor:pointer;
        img{
            width:100%;
            object-fit:contain;
        }
    }
    p{
        text-align:center;
        margin:0;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: var(--color-text-tertiary);
    }
`

const QuestionRepeat = styled.div`
    display:flex;
    align-items:center;
    gap:8px;
    justify-content:center;
    margin-top: 30%;
    margin-bottom:40px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: var(--color-text-primary);
`

const RecordingIndicator = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    height:${props=> props.type === 'retell_lecture' ? '100%' : 'unset'};
    margin-top:16px;
    p{
        color: var(--color-text-tertiary);
        font-size: 16px;
        font-weight: 600;
        line-height:24px;
        text-align:center;
        &.silent-text{
            color: var(--color-text-error);
            font-size: 16px;
            font-weight: 600;
            line-height: 150%;
            display:flex;
            align-items:center;
            gap: 4px;
        }
    }
`

const RecordingAnimation = css`
    padding: 1rem;
    border-radius: 50%;
    position: relative;
    max-width: 5rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    
    img {
        position: relative;
        z-index: 100;
    }
    
    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        z-index: 10;
        animation: ${pulse} 4s ease-out infinite;
    }
    
    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        z-index: 20;
        animation: ${pulse} 4s ease-out infinite;
        animation-delay: 2s;
    }
`

const SuccessMessage = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    height:100%;
    p{
        color: var(--color-text-success);
        font-size: 16px;
        font-weight: 600;
        line-height: 150%; 
        text-align:center;
    }
`

const AbortMessageContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    justify-content:space-between;
`

const AbortMessage = styled.div`
    display: flex;
    // align-items: center;
    flex-direction: column;
    height: 100%;
    justify-content:center;
    img{
        width:40px;
        height:40px;
        margin: 0 auto;
    }
    p{
        color: var(--color-text-secondary);
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
    }
    p:first-of-type{
        color: #000;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        text-align:center;
        width:100%;
    }
`

const AbortButtons = styled.div`
    display:flex;
    align-items:center;
    justify-content: space-between;
    width:100%;
    gap:10px;
    button, a{
        flex:1;
    }
`

