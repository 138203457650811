import {useEffect, useState, useRef, createRef} from 'react'
import { useNavigate, Link } from 'react-router-dom';
import {TaskContainer, FocusTag, TextColorGreen, TextColorOrange, TextColorRed, TextColorBlue, SecondaryButton, PrimaryButton, Modal, Overlay} from "../global_styles/global_styles"
import { cx, css } from '@emotion/css'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import {getTypeProperName} from "../util/getTypeProperName.js"
import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, RadialBarChart, RadialBar, PolarAngleAxis} from 'recharts';
import { useSelector, useDispatch } from 'react-redux';
import { processAndSaveUIFeedback } from '../util/processAndSaveUIFeedback.js'
import {addNewFeedback, updateFeedback} from "../redux/feedbackSlice.js"
import { feedback_strings } from '../data/feedback_strings.js'
import { scoring_thresholds } from '../data/scoring_thresholds.js';
import { getBreakdownValue } from "../util/getBreakdownValue.js"
import { getBreakdownColor } from '../util/getBreakdownColor.js';
import mixpanel from "mixpanel-browser";
import { useLoadingTime } from '../util/LoadingTimeProvider';
import { saveLoadTime } from '../util/saveLoadTime.js';

import questionIcon from "../images/question_pink.svg"
import speaker from '../images/speaker.svg'
import check from "../images/check-circle-green.svg"
import incorrect from "../images/cross-circle.svg"
import listenFill from "../images/listen_fill.svg"
import listenBackground from "../images/listen_background.svg"
import play from "../images/play-primary.svg"
import info from "../images/info-dark.svg"
import close from "../images/cross-circle-dark.svg"


function Score({questionData, questionId, typeData}) {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { startLoading, endLoading, resetTimes, startTime, endTime } = useLoadingTime();

    const root = document.documentElement;    
    const colorBrandPrimary50Inverse = getComputedStyle(root).getPropertyValue('--color-brand-primary-50-inverse');   

    const userProfile = useSelector(state => state.userProfile)
    const userAttempts = useSelector(state => state.userAttempts)
    const processedFeedback = useSelector(state => state.processedFeedback) //feedback for all questions attempted


    const [feedback, setFeedback] = useState(null) //processed feedback for this question only
    const [feedbackId, setFeedbackId] = useState(null)
    const [overallData, setOverallData] = useState([])
    const [breakdownData, setBreakdownData] = useState([])
    const [pronunciationAudio, setPronunciationAudio] = useState([])
    const [pronunciationAudioNodes, setPronunciationAudioNodes] = useState([])
    const [definition, setDefinition] = useState({})
    const [totalTime, setTotalTime] = useState(0)
    const [currentTime, setCurrentTime] = useState(0)
    const [playDisabled, setPlayDisabled] = useState(false)
    const [audioPlaying, setAudioPlaying] = useState(false) //needed?
    const [focusArea, setFocusArea] = useState(null)
    const [attemptAborted, setAttemptAborted] = useState(false)
    const [showScoringInfoModal, setShowScoringInfoModal] = useState(false)
    const [scoringInfoCategory, setScoringInfoCategory] = useState(null)

    const shortQuestionAudioRef = useRef(null)

    const toggleScoringInfoModal = (category) => {
        setShowScoringInfoModal(!showScoringInfoModal)
        if(category){
            setScoringInfoCategory(category)
        }else{
            setScoringInfoCategory(null)
        }
    }

    const loadFeedbackData = async () => {
        let feedbackMatch = [];
        if(processedFeedback.length > 0){
            feedbackMatch = processedFeedback.filter(feedback => feedback.questionId === Number(questionId))
        }
        let attemptMatch = []
        if(userAttempts.length > 0){
            attemptMatch = userAttempts.filter(attempt => attempt.questionId === Number(questionId))
        }

        //Check if redux has processedFeedback for the given questionId
        if(feedbackMatch[0]){
            //Yes?
            //check feedbackId against latestFeedback id to see if this is actually the latestest feedback or if new feedback is available
            if(feedbackMatch[0].feedbackId === attemptMatch[0].latestFeedback._id){
                //ids are the same? save to state and render
                // console.log('loading from redux')
                setFeedback(feedbackMatch[0].feedback)
                setFeedbackId(feedbackMatch[0].feedbackId)
            }else{
                // console.log('ids dont match')
                //ids don't match, need to process new feedback and update in redux
                //first make sure the evaluations are available to proccess
                if(
                    (typeData.type === 'short_question' && attemptMatch[0].latestFeedback.pafEvaluation) ||
                    (typeData.type != 'short_question' && attemptMatch[0].latestFeedback.pafEvaluation && attemptMatch[0].latestFeedback.contentEvaluation)
                )
                {
                    processFeedback(attemptMatch[0].latestFeedback, 'update')
                }
            }
        }else{
            //No processedFeedback?
            //First check if latestfeedback has processedFeedback and it just isn't saved in redux
            if(attemptMatch[0]){
                // console.log('loading from user attempts')
                setFeedbackId(attemptMatch[0].latestFeedback._id)
                // console.log(attemptMatch[0])
                if(attemptMatch[0].latestFeedback.processedFeedback){
                    //Yes? dispatch save to redux which should trigger loadFeedbackData to run again
                    let feedbackData = {
                        questionId: Number(questionId),
                        feedbackId: attemptMatch[0].latestFeedback._id,
                        feedback: attemptMatch[0].latestFeedback.processedFeedback
                    }
                    dispatch(addNewFeedback(feedbackData))
                //Still No? then check if latestfeedback has pasEval and contentEval    
                }else if(!attemptMatch[0].latestFeedback.processedFeedback){
                    if(
                        (typeData.type === 'short_question' && attemptMatch[0].latestFeedback.pafEvaluation) ||
                        (typeData.type != 'short_question' && attemptMatch[0].latestFeedback.pafEvaluation && attemptMatch[0].latestFeedback.contentEvaluation)
                    ){
                        processFeedback(attemptMatch[0]?.latestFeedback, 'add')
                    }else{
                        //show loading or error here?
                        // console.log('show error here')
                        setAttemptAborted(true)
                    }
                    
                }
            }
        }
    }

    const processFeedback = (latestFeedback, reduxAction) => {
        let apiData;
        let feedbackId = latestFeedback._id
        if(typeData.type === "short_question"){
            apiData = {
                pafData: latestFeedback.pafEvaluation
            }
        }else{
            apiData = {
                pafData: latestFeedback.pafEvaluation,
                contentData: latestFeedback.contentEvaluation
            }
        }
        processAndSaveUIFeedback(apiData, typeData, questionData, feedbackId)
        .then(result => {
            endLoading() //LoadingTimeProvider
            let feedbackData = {
                questionId: Number(questionId),
                feedbackId: latestFeedback._id,
                feedback: result
            }
            //then save to redux which should trigger the useEffect to run again
            if(reduxAction === 'add'){
                dispatch(addNewFeedback(feedbackData))
            }else if(reduxAction === 'update'){
                // console.log('update')
                dispatch(updateFeedback(feedbackData))
            }
            
        })
        
    }

    const getDefinition = () => {
        let dictionaryUrl = `https://apigw-us-gip-01.pgi.pearsonprd.tech/v2/dictionaries/entries?headword=${questionData.answer}`
        fetch(dictionaryUrl, {
            method: "get",
            headers:{
                "apikey": process.env.REACT_APP_DICTIONARY_KEY
            },
        })
        .then(response=>response.json())
        .then(data=>{
            let foundMatch;
            for(let i = 0; i < data.results.length; i++){
                if(data.results[i].datasets.includes('wordwise')){
                    for(let j = 0; j < data.results[i].senses.length; j++){
                        let item = data.results[i].senses[j]
                        if(item.definition && item.definition[0]){
                            let part_of_speech = data.results[i].part_of_speech
                            setDefinition({
                                definition: item.definition,
                                part_of_speech: String(part_of_speech).charAt(0).toUpperCase() + String(part_of_speech).slice(1)
                            })
                            foundMatch = true
                            return
                        }
                    }
                }
                if(foundMatch === true){
                    return
                }
            }
        })
    }

    const handlePlayShortQuestionAudio = () => {
        shortQuestionAudioRef.current.play()
        setAudioPlaying(true)
        setPlayDisabled(true)
    }

    const handleEnded = () => {
        setAudioPlaying(false)
        setPlayDisabled(false)
        setCurrentTime(0);
    }

    const handleGetDuration = () => {
        setTotalTime(shortQuestionAudioRef.current.duration)
    }

    const handleTimeUpdate = () => {
        setCurrentTime(shortQuestionAudioRef.current.currentTime);
    }

    const getFeedbackString = (topic, score) => {
        if(topic === 'content'){
            let topicGroup = feedback_strings[`${topic}_overall`] 
            let subGroup = typeData.type
            if(score === "Low"){
                return topicGroup[subGroup][0]
            }else if(score === "Mid"){
                return topicGroup[subGroup][1]
            }else if(score === "High"){
                return topicGroup[subGroup][2]
            }
        }else{
            let topicGroup = feedback_strings[`${topic}_overall`] 
            if(score === "Low"){
                return topicGroup[0]
            }else if(score === "Mid"){
                return topicGroup[1]
            }else if(score === "High"){
                return topicGroup[2]
            }
        }
    }

    const getContentCardFeedback = () => {
        let returnText = ""
        let contentRounded = Math.round(feedback.contentOverall)
        feedback_strings.content_closed_ended.forEach(range => {
            if(contentRounded >= range.score[0] && contentRounded <= range.score[1]){
                returnText = range.text
            }
        })
        return returnText
    }

    const  fetchWordAudio = async (words) => {
        const fetchPromises = words.map(word => {
            let dictionaryUrl = `https://apigw-us-gip-01.pgi.pearsonprd.tech/v2/dictionaries/entries?headword=${word.word}`
            return fetch(dictionaryUrl, {
                method: "get",
                headers:{
                    "apikey": process.env.REACT_APP_DICTIONARY_KEY
                },
            })
            .then(response=>response.json())
            .then(data=>{
                let newWord = Object.assign({}, word)
                if(data.results.length > 0){
                    data.results.forEach(result=>{
                        if(result.pronunciations && result.pronunciations[0].audio && result.pronunciations[0].audio[0]){
                            newWord.audioUrl = 'https://apigw-us-gip-01.pgi.pearsonprd.tech' + result.pronunciations[0].audio[0].url
                        }
                    })
                }
                return newWord
            })
        })
      
        const results = await Promise.all(fetchPromises);
        return results;
      };


    const getPronunciationAudio = async () => {
        let mispronunciations = feedback.wordsToImprove
        fetchWordAudio(mispronunciations)
        .then(results => {
            let wordsWithAudio = results.filter(obj => obj.audioUrl);
            let wordsWithoutAudio = results.filter(obj => !obj.audioUrl);
            setPronunciationAudio([...wordsWithAudio, ...wordsWithoutAudio].slice(0, 3))
        });
    }

    const highlightGrapheme = (word) => {
        const before = word.word.slice(0, word.graphemeOffset);
        const grapheme = word.word.slice(word.graphemeOffset, word.graphemeOffset + word.graphemeLength);
        const after = word.word.slice(word.graphemeOffset + word.graphemeLength);
      
        return (
          <span>
            {before}
            <span className="highlight">{grapheme}</span>
            {after}
          </span>
        );
      };

    const handlePlayPronunciationAudio = (index) => {
        pronunciationAudioNodes[index].current.play()
    }

    const getFocusArea = () => {
        // let areas = [
        //     {
        //         name: "content",
        //         score: feedback.contentOverall
        //     },
        //     {
        //         name: "fluency",
        //         score: feedback.fluencyOverall
        //     },
        //     {
        //         name: "pronunciation",
        //         score: feedback.pronunciationOverall
        //     }
        // ]

        // const lowest = areas.reduce((min, area) => (area.score < min.score ? area : min), areas[0]);
        // setFocusArea(lowest.name)
        if(feedback.contentOverallLabel === "Low"){
            setFocusArea("content")
        }else if(feedback.fluencyOverallLabel === "Low"){
            setFocusArea("fluency")
        }else if(feedback.pronunciationOverallLabel === "Low"){
            setFocusArea("pronunciation")
        }
    }

    //load feedback data
    useEffect(()=>{
        if(!feedback){
            loadFeedbackData()
        }
    },[processedFeedback, userAttempts])

    //load recharts data
    useEffect(()=>{
        if(feedback){
            if(typeData.type != "short_question"){

                // if( (typeData.type !== "describe_image" && typeData.type !== "retell_lecture") ||
                //     (typeData.type === "describe_image" || typeData.type === "retell_lecture") && feedback.someContentObjectivesMet != false
                // )
                
                if(
                    ((typeData.type === "describe_image" || typeData.type === "retell_lecture") && feedback.someContentObjectivesMet === false) ||
                    (typeData.type === "read_aloud" && feedback.contentOverall <= 0.03) ||
                    (typeData.type === "repeat_sentence" && feedback.contentOverall <= 0)
                ){
                    // console.log('show abort response messsage on score page')
                    setAttemptAborted(true)
                }else{
                    setOverallData(
                        [
                            { name: 'Score', value: feedback.overallPercentage, color: colorBrandPrimary50Inverse},
                            { name: 'Background', value: (100 - feedback.overallPercentage), color: 'transparent'},
                        ]
                    )
                    setBreakdownData(
                        [
                            [{ name: 'Content', label: feedback.contentOverallLabel, value: getBreakdownValue(feedback.contentOverallLabel), color: getBreakdownColor(feedback.contentOverallLabel)}],
                            [{ name: 'Fluency', label: feedback.fluencyOverallLabel, value: getBreakdownValue(feedback.fluencyOverallLabel), color: getBreakdownColor(feedback.fluencyOverallLabel)}],
                            [{ name: 'Pronunciation', label: feedback.pronunciationOverallLabel, value: getBreakdownValue(feedback.pronunciationOverallLabel), color: getBreakdownColor(feedback.pronunciationOverallLabel)}]
                        ]
                    )
                    getPronunciationAudio()
                    getFocusArea()
                }
            }else{
                getDefinition()
            }
        }
    },[feedback])

    useEffect(()=>{
        if(pronunciationAudio.length > 0){
            setPronunciationAudioNodes(pronunciationAudio.map(()=>createRef()))
        }
    },[pronunciationAudio])

    useEffect(() => {
        if (startTime && endTime && feedbackId) {
          const totalTime = endTime - startTime;
        //   console.log(`Total loading time: ${totalTime.toFixed(2)}ms`);
        //   console.log(feedbackId)
          let data = {
            feedbackId: feedbackId,
            loadTime: `${totalTime.toFixed(2)}ms`
          }
          saveLoadTime(data)
          .then(()=>{
            resetTimes()
          })
        }
      }, [endTime, feedbackId]);

    return(
        <>
            {
                !feedback && !attemptAborted ?
                <TaskContainer>
                    <LoadingContainer>
                        <LoaderAnimation>
                            <span className='first'></span>
                            <span className='middle'></span>
                            <span className='last'></span>
                        </LoaderAnimation>
                    </LoadingContainer>
                </TaskContainer>
                :
                <>
                {
                    attemptAborted ? 
                    <AbortMessageContainer>  
                        <AbortMessage>
                            <img src={info}/>
                            <p>Unable to produce a score</p>
                            {
                                typeData.type === "repeat_sentence" || typeData.type === "short_question" ? 
                                <p>Unfortunately, your response was too off topic.</p>
                                : 
                                <p>Unfortunately, your response was too off topic or less than 10 seconds.</p>
                            }
                            <p>It did not contain enough relevant content for our scoring engine to mark.</p>
                            <p>Please try again or return to the dashboard.</p>
                        </AbortMessage>
                        <AbortButtons>
                            {/* <Link
                                to={`/question/${questionId}`}
                                onClick={() => {
                                    mixpanel.track('Return to Dashboard from attempt aborted (unable to score)', { 'questionId': questionId })      
                                }}
                                className={css(SecondaryButton)}>
                                Retry question
                            </Link> */}
                            <button
                                className={SecondaryButton}
                                onClick={() => {
                                    mixpanel.track('Try question again from attempt aborted (unable to score score page)', {
                                        action: 'Retry question', // Describes the action
                                        buttonLabel: 'Retry question', // Logs the label of the button
                                    });
                                    navigate(process.env.PUBLIC_URL + `/question/${questionId}`)
                                    window.location.reload();
                                }}
                            >
                                Retry question
                            </button>
                            <Link
                                to="/dashboard"
                                onClick={() => {
                                    mixpanel.track('Return to Dashboard from attempt aborted (unable to score score page)', { 'questionId': questionId })      
                                }}
                                className={css(PrimaryButton)}>
                                Return to Dashboard
                            </Link>
                        </AbortButtons>
                    </AbortMessageContainer> 
                    : 
                    <>
                    {
                        showScoringInfoModal ?
                        <>
                            <ScoreInfoModal className={Modal}>
                                <ScoreInfoHeader>
                                    <p>{scoringInfoCategory} Score</p>
                                    <button
                                        onClick={toggleScoringInfoModal}
                                    >
                                        <img src={close}/>
                                    </button>
                                </ScoreInfoHeader>
                                {
                                    scoringInfoCategory === "Fluency" ?
                                    <ScoreInfoBody>
                                        <p>Speed, hesitations, unexpected pauses and repetitions do not influence the fluency score but are provided as extra feedback to help improve your speech</p>
                                        <p>For example, a low score could be achieved despite having zero hesitations, unexpected pauses or repetitions.</p>
                                    </ScoreInfoBody>
                                    : 
                                    <ScoreInfoBody>
                                        <p>The pronunciation score shows the ratio of correct sounds to the total sounds spoken. Mispronunciations (incorrect sounds) are shown as additional feedback to highlight specific areas for improvement.</p>
                                        <p>For example, a high score could be achieved but have many words  mispronounced.</p>
                                    </ScoreInfoBody>
                                }
                            </ScoreInfoModal>
                            <div className={Overlay}></div>
                        </>
                        
                        : null
                    }
                    <ScoreBody>
                    <ScoreH1>{getTypeProperName(questionData.type)}</ScoreH1>
                    {
                        typeData.type === "short_question" ?
                        <>
                            <ShortQuestionCard>
                                <ShortQuestionFeedback className={feedback.short_question_match === true ? ListItemGreen : ListItemRed}>
                                    {
                                        feedback.short_question_match === true ? 
                                        <p>
                                            <img src={check}/>
                                            <span className={feedback.short_question_match === true ? TextColorGreen : TextColorRed}>Correct!</span>
                                        </p> 
                                        : 
                                        <p>
                                            <img src={incorrect}/>
                                            <span className={feedback.short_question_match === true ? TextColorGreen : TextColorRed}>Incorrect</span>
                                        </p>
                                    }
                                    <p>You said: {String(feedback.transcript).charAt(0).toUpperCase() + String(feedback.transcript).slice(1)}</p>
                                </ShortQuestionFeedback>
                                {
                                    feedback.short_question_match === false ? 
                                    <CorrectAnswer>
                                        <p>
                                            <img src={check}/>
                                            <span className={TextColorGreen}>The accepted answer is</span>
                                        </p> 
                                        <p>{String(questionData.answer).charAt(0).toUpperCase() + String(questionData.answer).slice(1)}</p>
                                    </CorrectAnswer> 
                                    : null
                                }
                                {
                                    definition.definition ? 
                                    <DefinitionContainer>
                                        <DefinitionLabel>Definition</DefinitionLabel>
                                        <PartOfSpeech>{definition.part_of_speech}</PartOfSpeech>
                                        <Definition>{definition.definition}</Definition>
                                    </DefinitionContainer>
                                    :  null
                                }
                            </ShortQuestionCard>
                            <ListenAnimation>
                                <Mask totalTime={totalTime} currentTime={currentTime}>
                                    <img src={listenFill}/>
                                </Mask>
                                <ListenBackground src={listenBackground}/>
                            </ListenAnimation>
                            <audio 
                                ref={shortQuestionAudioRef}
                                onEnded={handleEnded} 
                                onLoadedMetadata={handleGetDuration}
                                onTimeUpdate={handleTimeUpdate} 
                            >
                                <source src={questionData.question.audio} type="audio/mpeg"/>
                            </audio>
                            <button
                                className={cx(SecondaryButton, ReplayButton)}
                                onClick={handlePlayShortQuestionAudio}
                                disabled={playDisabled}
                            >
                                 Replay question
                                 <img src={play}/>
                            </button>
                        </>
                        : 
                        <>
                        <OverallScore>
                            <OverallScoreChart>
                                <PieChart className="pie" width={100} height={100} >
                                    <Pie
                                        data={overallData}
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={41}
                                        outerRadius={49}
                                        cornerRadius={10}
                                        dataKey="value"
                                        // paddingAngle={5}
                                        startAngle={90} 
                                        endAngle={-270}
                                        isAnimationActive={true}
                                        >
                                        {overallData.map((entry, index) => (
                                            <Cell 
                                                key={`cell-${index}`}
                                                stroke={entry.color}
                                                fill={entry.color}
                                            />
                                        ))}
                                        </Pie>
                                    </PieChart>
                                    <svg className="pieBackground" xmlns="http://www.w3.org/2000/svg" width="100" height="101" viewBox="0 0 100 101" fill="none">
                                        <path d="M100 50.5C100 78.1142 77.6142 100.5 50 100.5C22.3858 100.5 0 78.1142 0 50.5C0 22.8858 22.3858 0.5 50 0.5C77.6142 0.5 100 22.8858 100 50.5ZM10 50.5C10 72.5914 27.9086 90.5 50 90.5C72.0914 90.5 90 72.5914 90 50.5C90 28.4086 72.0914 10.5 50 10.5C27.9086 10.5 10 28.4086 10 50.5Z" fill="#FAFAFA"/>
                                    </svg>
                                    <CustomLabelPie>
                                        <span>
                                            {feedback.overallPercentage}%
                                        </span>
                                    </CustomLabelPie>
                                </OverallScoreChart>
                            <h2>Overall score</h2>
                        </OverallScore>
                        {
                            breakdownData.map((dataItem, index) => {
                                return(
                                    <ScoreBreakdownCard key={index}>
                                        <ScoreBreakdownChart>
                                            <CustomLabelLeft>
                                                {dataItem.map((entry, index) => (
                                                    entry.name
                                                ))}
                                            </CustomLabelLeft>
                                            <BarChart 
                                                width={200}
                                                height={20}
                                                barSize={12}
                                                data={dataItem}
                                                layout="vertical"
                                            >
                                                <XAxis type="number" hide="true" domain={[0, 100]}/>
                                                <YAxis type="category" hide="true"/>
                                                <Bar 
                                                    dataKey="value"
                                                    fill='red'
                                                    background={{ fill: '#eee', radius: 12 }}
                                                    radius={12}
                                                >
                                                    {dataItem.map((entry, index) => (
                                                        <Cell 
                                                            key={`cell-${index}`}
                                                            fill={entry.color}
                                                        />
                                                    ))}
                                                </Bar>
                                            </BarChart>
                                            <CustomLabelRight>
                                                {dataItem.map((entry, index) => (
                                                    entry.label
                                                ))}
                                            </CustomLabelRight>
                                        </ScoreBreakdownChart>
                                        <OverallFeedbackText>{getFeedbackString(dataItem[0].name.toLowerCase(), dataItem[0].label)}</OverallFeedbackText>
                                    </ScoreBreakdownCard>
                                )
                            })
                        }
                        <DetailCard
                            className={css(ContentDetailCard, focusArea === "content" ? FocusArea : "")}
                            onClick={()=>{
                                navigate(process.env.PUBLIC_URL + `/question/${questionId}/score/content`)
                            }}
                        >
                            {focusArea === "content" ? <FocusTag>Focus area</FocusTag> : null}
                            <DetailCardHeader>
                                <h2>Content</h2>
                                {
                                    breakdownData.length > 0 ? 
                                    <ScoreBreakdownChartRadial>
                                        <RadialBarChart 
                                            width={100} 
                                            height={50} 
                                            cx="50%"
                                            cy="100%"
                                            barSize={10}
                                            innerRadius={42}
                                            outerRadius={100}
                                            data={breakdownData[0]} 
                                            startAngle={180} 
                                            endAngle={0}
                                            isAnimationActive={true}
                                            >
                                                <RadialBar cornerRadius={12} background clockWise={false} dataKey='value'>
                                                    {breakdownData[0].map((entry, index) => (
                                                        <Cell 
                                                            key={`cell-${index}`}
                                                            fill={entry.color}
                                                        />
                                                    ))}
                                                </RadialBar>
                                                <PolarAngleAxis type="number" hide="true" domain={[0, 100]} />
                                        </RadialBarChart>
                                        <CustomLabelRadial>
                                            {breakdownData[0].map((entry, index) => (
                                                entry.label
                                            ))}
                                        </CustomLabelRadial>
                                    </ScoreBreakdownChartRadial>
                                    : null
                                }
                                
                            </DetailCardHeader>
                            {
                                feedback.contentObjectives ?
                                <MetricList>
                                    {Object.keys(feedback.contentObjectives).map((category, index) => (
                                        <MetricListItem key={index} className={css(feedback.contentObjectives[index].pass === "Perfect" ? ListItemGreen : feedback.contentObjectives[index].pass === "Almost" ? ListItemOrange : ListItemRed)}>
                                                <span>{feedback.contentObjectives[index].label}</span>
                                                <span 
                                                    className={css(feedback.contentObjectives[index].pass === "Perfect" ? TextColorGreen : feedback.contentObjectives[index].pass === "Almost" ? TextColorOrange : TextColorRed)} 
                                                    >
                                                        {feedback.contentObjectives[index].pass}
                                                </span>
                                        </MetricListItem>
                                    ))}
                                </MetricList>
                                : null
                            }
                            {
                                typeData.type === "read_aloud" || typeData.type === "repeat_sentence" ?
                                <>
                                    <ContentFeedbackPos className={ListItemGreen}>
                                        <p className={ListItemLabelGreen}>
                                            <img src={check}/>
                                            <span>What went well</span>
                                        </p>
                                        <p>{getContentCardFeedback()}</p>
                                    </ContentFeedbackPos>
                                </> 
                                : null
                            }
                            
                            <DetailCardFooter>More Detail</DetailCardFooter>
                        </DetailCard>
                        <DetailCard
                            className={css(focusArea === "fluency" ? FocusArea : "")}
                        >
                            {focusArea === "fluency" ? <FocusTag>Focus area</FocusTag> : null}
                            <DetailCardHeader>
                                <h2>Fluency</h2>
                                {
                                    breakdownData.length > 0 ? 
                                    <ScoreBreakdownChartRadial>
                                        <RadialBarChart 
                                            width={100} 
                                            height={50} 
                                            cx="50%"
                                            cy="100%"
                                            barSize={10}
                                            innerRadius={42}
                                            outerRadius={100}
                                            data={breakdownData[1]} 
                                            startAngle={180} 
                                            endAngle={0}
                                            isAnimationActive={true}
                                            >
                                                <RadialBar cornerRadius={12} background clockWise={false} dataKey='value'>
                                                    {breakdownData[1].map((entry, index) => (
                                                        <Cell 
                                                            key={`cell-${index}`}
                                                            fill={entry.color}
                                                        />
                                                    ))}
                                                </RadialBar>
                                                <PolarAngleAxis type="number" hide="true" domain={[0, 100]} />
                                        </RadialBarChart>
                                        <CustomLabelRadial>
                                            {breakdownData[1].map((entry, index) => (
                                                entry.label
                                            ))}
                                        </CustomLabelRadial>
                                    </ScoreBreakdownChartRadial>
                                    : null
                                }
                            </DetailCardHeader>
                            <MetricList>
                                <MetricListItem className={css(feedback.speed === 'Normal' ? ListItemGreen : ListItemBlue)}>
                                        <span>
                                            Speed
                                            <img className='tooltip-speed' src={questionIcon}/>
                                        </span>
                                        <span className={css(feedback.speed === 'Normal' ? TextColorGreen : TextColorBlue)}>
                                            {feedback.speedLabel}
                                        </span>
                                </MetricListItem>
                                <MetricListItem className={css(feedback.hesitations === 0 ? ListItemGreen : feedback.hesitations === 1 ? ListItemOrange : ListItemRed)}>
                                        <span>
                                            Hesitations
                                            <img className='tooltip-hesitations' src={questionIcon}/>
                                        </span>
                                        <span className={css(feedback.hesitations === 0  ? TextColorGreen : feedback.hesitations >= 1 && feedback.hesitations <= scoring_thresholds.hesitations.max ? TextColorOrange : TextColorRed)}>
                                        {feedback.hesitationsLabel}
                                        </span>
                                </MetricListItem>
                                <MetricListItem className={css(feedback.unexpectedPauses <= 1 ? ListItemGreen : feedback.unexpectedPauses >= 2 && feedback.unexpectedPauses <= scoring_thresholds.unexpected_pauses.max ? ListItemOrange : ListItemRed)}>
                                        <span>
                                            Unexpected pauses
                                            <img className='tooltip-pauses' src={questionIcon}/>
                                        </span>
                                        <span className={css(feedback.unexpectedPauses <= 1 ? TextColorGreen : feedback.unexpectedPauses >= 2 && feedback.unexpectedPauses <= scoring_thresholds.unexpected_pauses.max ? TextColorOrange : TextColorRed)}>
                                            {feedback.unexpectedPausesLabel}
                                        </span>
                                </MetricListItem>
                                <MetricListItem className={css(feedback.repetitions === 0 ? ListItemGreen : feedback.repetitions === 1 ? ListItemOrange : ListItemRed)}>
                                        <span>
                                            Repetitions
                                            <img className='tooltip-repetitions' src={questionIcon}/>
                                        </span>
                                        <span className={css(feedback.repetitions === 0  ? TextColorGreen : feedback.repetitions >= 1 && feedback.repetitions <= scoring_thresholds.repetitions.max ? TextColorOrange : TextColorRed)}>
                                        {feedback.repetitionsLabel}
                                        </span>
                                </MetricListItem>
                            </MetricList>
                            <DetailCardTextButton
                                onClick={()=>{toggleScoringInfoModal("Fluency")}}
                            >
                                <span>How is this section scored?</span>
                                <img src={info}/>
                            </DetailCardTextButton>
                        </DetailCard>
                        <DetailCard
                            className={css(focusArea === "pronunciation" ? FocusArea : "")}
                        >
                            {focusArea === "pronunciation" ? <FocusTag>Focus area</FocusTag> : null}
                            <DetailCardHeader>
                                <h2>Pronunciation</h2>
                                {
                                    breakdownData.length > 0 ? 
                                    <ScoreBreakdownChartRadial>
                                        <RadialBarChart 
                                            width={100} 
                                            height={50} 
                                            cx="50%"
                                            cy="100%"
                                            barSize={10}
                                            innerRadius={42}
                                            outerRadius={100}
                                            data={breakdownData[2]} 
                                            startAngle={180} 
                                            endAngle={0}
                                            isAnimationActive={true}
                                            >
                                                <RadialBar cornerRadius={12} background clockWise={false} dataKey='value'>
                                                    {breakdownData[2].map((entry, index) => (
                                                        <Cell 
                                                            key={`cell-${index}`}
                                                            fill={entry.color}
                                                        />
                                                    ))}
                                                </RadialBar>
                                                <PolarAngleAxis type="number" hide="true" domain={[0, 100]} />
                                        </RadialBarChart>
                                        <CustomLabelRadial>
                                            {breakdownData[2].map((entry, index) => (
                                                entry.label
                                            ))}
                                        </CustomLabelRadial>
                                    </ScoreBreakdownChartRadial>
                                    : null
                                }
                            </DetailCardHeader>
                            <MetricList>
                                <MetricListItem className={css(feedback.mispronunciations === 'A few' || feedback.mispronunciations === 'Some' ? ListItemOrange : feedback.mispronunciations === 'None' ? ListItemGreen : ListItemRed)}>
                                        {/* <span>Mispronunciations</span> */}
                                        <span>
                                            Mispronunciations
                                            <img className='tooltip-mispronunciations' src={questionIcon}/>
                                        </span>
                                        <span className={css(feedback.mispronunciations === 'A few' || feedback.mispronunciations === 'Some' ? TextColorOrange : feedback.mispronunciations === 'None' ? TextColorGreen : TextColorRed)}>
                                            {feedback.mispronunciationsLabel}
                                        </span>
                                </MetricListItem>
                            </MetricList>
                            <WordsListContainer>
                                <h3>Words you mispronounced</h3>
                                {
                                    pronunciationAudio.length === 0 ?
                                        <p>No words with 2 syllables or more were mispronounced</p>
                                    :
                                    <>
                                        <p>Listen to the audio and pay attention to the highlighted sounds.</p>
                                        <WordsList>
                                            {
                                                pronunciationAudio.map((word, index)=>{
                                                    return(
                                                        <WordsListItem key={index}>
                                                                {
                                                                    word.audioUrl ? 
                                                                    <>
                                                                        <button onClick={()=>{handlePlayPronunciationAudio(index)}}>
                                                                            {highlightGrapheme(word)}
                                                                            <img src={speaker}/>
                                                                        </button>
                                                                        <audio 
                                                                            ref={pronunciationAudioNodes[index]}
                                                                        >
                                                                            <source src={word.audioUrl} type="audio/mpeg"/>
                                                                        </audio>
                                                                    </>
                                                                    :
                                                                    <span>
                                                                        {highlightGrapheme(word)}
                                                                    </span>
                                                                }
                                                                
                                                        </WordsListItem>
                                                    )
                                                })
                                            }
                                        </WordsList>
                                        <p>Some words listed may not be supported with audio playback.</p>
                                    </>
                                }
                                
                            </WordsListContainer>
                            <DetailCardTextButton
                                onClick={()=>{toggleScoringInfoModal("Pronunciation")}}
                            >
                                <span>How is this section scored?</span>
                                <img src={info}/>
                            </DetailCardTextButton>
                        </DetailCard>
                        </>
                    }
                    </ScoreBody>
                    <Link
                        to="/dashboard"
                        onClick={() => {
                            mixpanel.track('Try Again', { 'questionId': questionId })      
                        }}
                        className={css(PrimaryButton)}>
                        Return to Dashboard
                    </Link>
                    </>
                    }
                </>
            }
            
        </>
    )
}

export default Score

const bounce = keyframes`
    0%,30%{
        transform:translate(0px);
        background: var(--color-monochrome-15);
    }
    50%  {
        transform:translate(0, -8px);
        background: var(--color-brand-primary-50);
    }
    100% {
        transform:translate(0px);
        background: var(--color-monochrome-15);
    }
`

const LoadingContainer = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    height:100%;
`

const LoaderAnimation = styled.div`
    margin:16px;
    position: relative;
    float:left;
    // width: 62px;
    height: 32px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    gap:6px;
    padding: 0 16px;
    box-sizing:border-box;
    span{
        display:block;
        height:8px;
        width:8px;
        border-radius:8px;
        background: var(--color-monochrome-15);
        animation: ${bounce} 2s infinite ease;
        &.first{
            animation-delay:200ms;
        }
        &.middle{
            animation-delay:500ms;
        }
        &.last{
            animation-delay:800ms;
        }
    }
`

// const ScoreContainer = styled.div`
//     margin-left: -16px;
//     width: calc(100% + 32px);
//     padding:16px;
//     flex:1;
// `

const ScoreBody = styled.div`
    margin-left: -16px;
    width: calc(100% + 32px);
    padding: 0 16px;
    flex:1;
    position: relative;
`

const ScoreH1 = styled.h1`
    color: #000;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
`

const OverallScore = styled.div`
    display:flex;
    align-items:center;
    gap:24px;
    margin-top:45px;
    margin-bottom:45px;
    h2{
        width:100%;
        text-align:center;
        font-size: 32px;
        font-weight: 600;
        line-height: 40px;
        color: #000;
    }
`

const OverallScoreChart = styled.div`
    height: 100px;
    width:100px;
    position:relative;
    .pie{
        z-index:2;
        // transform:rotate(90deg);
    }
    .pieBackground{
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%, -50%);
        z-index:1;
    }
`

const CustomLabelPie = styled.div`
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  span{
    text-align:center;
    font-size: 24px;
    font-weight: 400;
    line-height: 160%; 
    color: #000;
  }
`

const ScoreBreakdownCard = styled.div`  
    border-radius: 8px;
    background: var(--color-fill-section-secondary);
    padding:8px;
    margin-bottom:12px;
    &>p{
       margin:0;
       margin-top:18px;
    }
`

const ScoreBreakdownChart = styled.div`
    display:flex;
    align-items:center;
    justify-content: space-between;
    gap:8px;    
`

const CustomLabelLeft = styled.p`
    margin:0;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: #000;
    width: 124px;
`

const CustomLabelRight = styled.p`
    margin:0;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #000;
    text-align:right;
`

const OverallFeedbackText = styled.p`
    color: var(--color-text-secondary);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
`

const DetailCard = styled.div`
    min-height:300px;
    border-radius: 12px;
    background: #FFF;
    margin-top:45px;
    padding:24px;
    box-shadow: var(--elevation-x-none, 0px) var(--elevation-y-2xl, 24px) var(--elevation-blur-3xl, 48px) 0px var(--elevation-default, rgba(8, 9, 9, 0.08));
`

const ContentDetailCard = css`
    cursor:pointer;
`

const FocusArea = css`
    border: 1px solid var(--color-stroke-interactive-focus);
`

const DetailCardTextButton = styled.button`
    display:flex;
    align-items:center;
    gap:8px;
    margin-top:32px;
    span{
        color: var(--color-text-primary);
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
    }
    img{
        width: 24px;
        height: 24px;
    }
`

const DetailCardHeader = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    h2{
        color: #000;
    }
`

const DetailCardFooter = styled.p`
    color: var(--color-text-interactive-brand-default);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin:0;
    margin-top:24px;
    width:100%;
    text-align:center;
`

const ScoreBreakdownChartRadial = styled.div`
    position:relative;
`

const CustomLabelRadial = styled.span`
    display:block;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    margin-top:12px;
    color: #000;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
`

const MetricList = styled.ul`
    margin-top:24px;
`

const MetricListItem = styled.li`
    padding: 8px 16px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    border-radius: 8px;
    margin-bottom:8px;
    span:first-of-type{
        display:flex;
        align-items:center;
        gap:8px;
        img{
            cursor:pointer;
        }
    }
    span:last-of-type{
        font-size:14px;
    }
`

const ListItemGreen = css`
    background: var(--color-fill-success)
`

const ListItemRed = css`
    background: var(--color-fill-error)
`

const ListItemOrange = css`
    background: var(--color-fill-warning)
`

const ListItemBlue = css`
    background: var(--color-fill-info)
`

const ListItemLabelGreen = css`
    color: var(--color-text-success, #008035);
`

const ContentFeedbackPos = styled.div`
    height:98px;
    padding:10px;
    border-radius: 8px;
    margin-top:36px;
    p{
        margin: 0
    }
    p:first-of-type{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        display:flex;
        align-items:center;
        gap:10px;
    }
    p:last-of-type{
        color:#000;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-top:10px;
    }
`

const WordsListContainer = styled.div`
    border-radius: 8px;
    background: var(--color-fill-section-secondary, #FAFAFA);
    padding:16px;
    h3{
        color: #000;
        font-size: 20px;
        font-weight: 600;
        line-height: 160%;
        margin:0;
    }
    p{
        color: #000;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-top:8px;
    }
`

const WordsList = styled.ul`
    
`

const WordsListItem = styled.li`
    button, &>span{
        display:flex;
        align-items:center;
        gap:43px;
        width:100%;
        text-align:left;
        margin: 20px 0;
        padding: 0;
        span{
            width:40%;
            color: #000;
            font-size: 20px;
            font-weight: 400;
            line-height: 160%;
            span.highlight{
                background: #D3E4FF;
                font-weight: 700;
            }
        }
    }
`
const ShortQuestionCard = styled.div`
    border-radius: 12px;
    border: 1px solid var(--color-stroke-container-secondary);
    background: var(--color-fill-container-primary);
    box-shadow: var(--elevation-x-none, 0px) var(--elevation-y-sm, 2px) var(--elevation-blur-xs, 4px) 0px var(--elevation-default, rgba(8, 9, 9, 0.08));
    padding:24px;
    margin-top:45px;
    margin-bottom:60px;
`

const ShortQuestionFeedback = styled.div`
    padding:10px;
    border-radius: 8px;
    p{
        margin: 0
    }
    p:first-of-type{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        display:flex;
        align-items:center;
        gap:10px;
    }
    p:last-of-type{
        color:#000;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        margin-top:10px;
    }
`

const CorrectAnswer = styled.div`
    p:first-of-type{
        display:flex;
        align-itemsz;center;
        gap: 8px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }
    p:last-of-type{
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
`

const DefinitionContainer = styled.div`
    p{
        color: #000;
    }
`

const DefinitionLabel = styled.p`
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    margin: 8px 0;
`

const PartOfSpeech = styled.p`
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 20px;
    margin: 8px 0;
`

const Definition = styled.p`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
`

const ListenAnimation = styled.div`
    width:285px;
    margin:0 auto;
    margin-top:12px;
    position:relative;
    height:80px;
`

const Mask = styled.div`
    width: ${props => (props.currentTime * 100) / props.totalTime}%;
    transform:width:100ms ease;
    overflow:hidden;
    position:absolute;
    top:0;
    left:0;
    z-index:1001;
`

const ListenBackground = styled.img`
    position:absolute;
    top:0;
    left:0;
    z-index:1000;
`

const ReplayButton = css`
    margin: 16px auto;
    width:192px;
    gap:8px;
`

const AbortMessageContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    justify-content:space-between;
`

const AbortMessage = styled.div`
    display: flex;
    // align-items: center;
    flex-direction: column;
    height: 100%;
    justify-content:center;
    img{
        width:40px;
        height:40px;
        margin: 0 auto;
    }
    p{
        color: var(--color-text-secondary);
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
    }
    p:first-of-type{
        color: #000;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        text-align:center;
        width:100%;
    }
`

const AbortButtons = styled.div`
    display:flex;
    align-items:center;
    justify-content: space-between;
    width:100%;
    gap:10px;
    button, a{
        flex:1;
    }
`

const ScoreInfoModal = styled.div`
    position:fixed;
    width: 328px;
    padding:0;
`

const ScoreInfoHeader = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding:16px;
    width:100%;
    border-bottom: var(--stroke-container-sm, 1px) solid var(--color-stroke-container-secondary, #E0E0E0);
    p{
        color: var(--color-text-primary);
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        margin:0;
    }
    button{
        width:24px;
        height:24px;
        display:flex;
        align-items:center;
        justify-content:center;
    }
`

const ScoreInfoBody = styled.div`
    padding:16px;
`