import { Tooltip } from 'react-tooltip'

function Tooltips(){
    return(
        <>
        {/* Dashboard */}
        <Tooltip 
            anchorSelect=".info"
            place="bottom"
            className='custom-tooltip-dark'
            openOnClick={true}
        >
            Suitable for PTE Academic and PTE Academic UKVI
        </Tooltip>
        {/* Score */}
        <Tooltip 
            anchorSelect=".tooltip-speed"
            place="bottom"
            className='custom-tooltip-light'
            openOnClick={true}
        >
            Your speed is compared to the average person. Consider adjusting your speed if you speak quicker than average and it is causing mis-pronunciations.
        </Tooltip>
        <Tooltip 
            anchorSelect=".tooltip-hesitations"
            place="bottom"
            className='custom-tooltip-light'
            openOnClick={true}
        >
            This is the number of spoken hesitations such as “err” “umm” or “arr”. 
        </Tooltip>
        <Tooltip 
            anchorSelect=".tooltip-pauses"
            place="bottom"
            className='custom-tooltip-light'
            openOnClick={true}
        >
            This is the number of unexpected pauses or breaks which may have disrupted the natural rhythm of a sentence.
        </Tooltip>
        <Tooltip 
            anchorSelect=".tooltip-repetitions"
            place="bottom"
            className='custom-tooltip-light'
            openOnClick={true}
        >
            This is the number of times your may have repeated a word or restarted your sentence.
        </Tooltip>
        <Tooltip 
            anchorSelect=".tooltip-mispronunciations"
            place="bottom"
            className='custom-tooltip-light'
            openOnClick={true}
        >
            This is the amount of mispronounced sounds in the response.
        </Tooltip>
        </>
    )
}

export default Tooltips