import {useEffect, useState, useRef, createRef} from 'react'
import { cx, css } from '@emotion/css'
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import {FocusTag, TextColorGreen, TextColorRed, TextColorOrange} from "../global_styles/global_styles"
import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, RadialBarChart, RadialBar, PolarAngleAxis} from 'recharts';
import { useSelector, useDispatch } from 'react-redux';
import {addNewFeedback} from "../redux/feedbackSlice.js"
import { getBreakdownValue } from "../util/getBreakdownValue.js"
import { getBreakdownColor } from '../util/getBreakdownColor.js';
import { feedback_strings } from '../data/feedback_strings.js';
import RetellLecture from './shared/RetellLectureScorePage.js';
import mixpanel from "mixpanel-browser";
import { getRecordingUrl } from '../util/getRecordingUrl.js';

import passIcon from "../images/pass-icon.svg"
import failIcon from "../images/fail-icon.svg"
import almostIcon from "../images/almost-icon.svg"
import arrowDown from "../images/arrow-down.svg"
import passageIcon from "../images/text-align-left.svg"
import wordsMissedIcon from "../images/chat-circle-dots.svg"
import check from "../images/check-circle.svg"
import speaker from "../images/speaker.svg"
import play from "../images/play-grey.svg"
import pause from "../images/pause.svg"
import playDark from "../images/play-dark.svg"


function ContentDetail({questionData, questionId, typeData}){

    const dispatch = useDispatch()

    const userAttempts = useSelector(state => state.userAttempts)
    const processedFeedback = useSelector(state => state.processedFeedback)

    const [feedback, setFeedback] = useState(null)
    const [accordionPanels, setAccordionPanels] = useState([])
    const [breakdownData, setBreakdownData] = useState([])
    const [activeTab, setActiveTab] = useState('transcript')
    const [tabPanels, setTabPanels] = useState([createRef(), createRef()])
    const [attempt, setAttempt] = useState(null)
    const [recordingUrl, setRecordingUrl] = useState(null)
    const [audioExamplePlaying, setAudioExamplePlaying] = useState(null)
    const [recordingPlaying, setRecordingPlaying] = useState(null)

    const audioExampleRef = useRef(null)
    const recordingRef = useRef(null)

    const loadFeedbackData = async () => {
        let feedbackMatch = [];
        if(processedFeedback.length > 0){
            feedbackMatch = processedFeedback.filter(feedback => feedback.questionId === Number(questionId))
        }
        let attemptMatch = []
        if(userAttempts.length > 0){
            attemptMatch = userAttempts.filter(attempt => attempt.questionId === Number(questionId))
        }
        setAttempt(attemptMatch[0]?.latestAttempt) //set this to get the audio response url
        //Check if redux has processedFeedback for the given questionId
        if(feedbackMatch[0]){
            //Yes?
            //check feedbackId against latestFeedback id to see if this is actually the latestest feedback or if new feedback is available
            if(feedbackMatch[0].feedbackId === attemptMatch[0].latestFeedback._id){
                //ids are the same? save to state and render
                console.log('loading from redux')
                setFeedback(feedbackMatch[0].feedback)
            }
        }else{
            if(attemptMatch[0]){    
                console.log('loading from user attempts')
                if(attemptMatch[0].latestFeedback.processedFeedback){
                    //Yes? dispatch save to redux which should trigger loadFeedbackData to run again
                    let feedbackData = {
                        questionId: Number(questionId),
                        feedbackId: attemptMatch[0].latestFeedback._id,
                        feedback: attemptMatch[0].latestFeedback.processedFeedback
                    }
                    dispatch(addNewFeedback(feedbackData))
                }
            }
        }
    }

    const handleAccordion = (button, index) => {
        if(button.getAttribute('aria-expanded') === 'true'){
            button.setAttribute('aria-expanded', false)
            accordionPanels[index].current.setAttribute('hidden', '');
        }else{
            button.setAttribute('aria-expanded', true)
            accordionPanels[index].current.removeAttribute('hidden');
        }
    }

    const getCategoryDescription = (category) => {
        let categoryMatch = feedback_strings.category_descriptions.filter(desc=>desc.category === category)
        return categoryMatch[0].description
    } 

    const handlePlayAudioExample = () => {
        if(audioExampleRef.current){
            audioExampleRef.current.play()
            setAudioExamplePlaying(true)
        }
    }

    const handlePauseAudioExample = () => {
        if(audioExampleRef.current){
            audioExampleRef.current.pause()
            setAudioExamplePlaying(false)
        }
    }

    const handlePlayRecording = () => {
        // console.log(recordingRef.current)
        if(recordingRef.current){
            recordingRef.current.play()
            setRecordingPlaying(true)
        }
    }

    const handlePauseRecording = () => {
        if(recordingRef.current){
            recordingRef.current.pause()
            setRecordingPlaying(false)
        }
    }

    //load feedback data
    useEffect(()=>{
        if(!feedback){
            loadFeedbackData()
        }
    },[processedFeedback, userAttempts])

    useEffect(()=>{
        if(feedback && feedback.contentObjectives && Object.keys(feedback.contentObjectives).length > 0){
            setAccordionPanels(Object.keys(feedback.contentObjectives).map(()=>createRef()))
        }
    },[feedback])

    //load recharts data
    useEffect(()=>{
        if(feedback){
            setBreakdownData(
                [
                    [{ name: 'Content', label: feedback.contentOverallLabel, value: getBreakdownValue(feedback.contentOverallLabel), color: getBreakdownColor(feedback.contentOverallLabel)}],
                ]
            )
        }
    },[feedback])

    //get users recording
    useEffect(()=>{
        if(attempt){
            getRecordingUrl({audioRecording: attempt.audioRecording})
            .then(response=>response.json())
            .then(data=>{
                // console.log(data)
                setRecordingUrl(data.url)
            })
        }
    },[attempt])

    return(
        <>
            {
                !feedback ? 
                    <ScoreContainer>
                        <LoadingContainer>
                            <LoaderAnimation>
                                <span className='first'></span>
                                <span className='middle'></span>
                                <span className='last'></span>
                            </LoaderAnimation>
                        </LoadingContainer>
                    </ScoreContainer>
                    :
            
                    <ScoreContainer>
                    {/* <FocusTag>Focus area</FocusTag> */}
                    <DetailHeader>
                        <h1>Content</h1>
                        {
                            breakdownData.length > 0 ? 
                            <ScoreBreakdownChartRadial>
                                <RadialBarChart 
                                    width={100} 
                                    height={50} 
                                    cx="50%"
                                    cy="100%"
                                    barSize={10}
                                    innerRadius={42}
                                    outerRadius={100}
                                    data={breakdownData[0]} 
                                    startAngle={180} 
                                    endAngle={0}
                                    isAnimationActive={true}
                                    >
                                        <RadialBar cornerRadius={12} background clockWise={false} dataKey='value'>
                                            {breakdownData[0].map((entry, index) => (
                                                <Cell 
                                                    key={`cell-${index}`}
                                                    fill={entry.color}
                                                />
                                            ))}
                                        </RadialBar>
                                        <PolarAngleAxis type="number" hide="true" domain={[0, 100]} />
                                </RadialBarChart>
                                <CustomLabelRadial>
                                    {breakdownData[0].map((entry, index) => (
                                        entry.label
                                    ))}
                                </CustomLabelRadial>
                            </ScoreBreakdownChartRadial>
                            : null
                        }
                    </DetailHeader>
                    <DetailBody>
                        {
                            typeData.type === 'repeat_sentence' ?
                                <>
                                    <ListenText
                                        onClick={() => {
                                            mixpanel.track('Audio Example Played', {
                                                audioSrc: questionData.question.audio, // Tracks the audio source
                                                questionId: questionData.question.id, // Tracks the question ID
                                            });
                                            {
                                                audioExamplePlaying === true ?
                                                handlePauseAudioExample()
                                                :
                                                handlePlayAudioExample()
                                            }
                                        }}
                                    >
                                        <p>Replay sentence</p>
                                        {
                                            audioExamplePlaying != null ?
                                            audioExamplePlaying === true ?
                                            <img src={pause} alt="Pause Audio"/>
                                            :
                                            <img src={playDark} alt="Play Audio"/>
                                            :
                                            <img src={speaker} alt="Play Audio"/>
                                        }
                                        <audio 
                                            ref={audioExampleRef}
                                            onEnded={()=>{setAudioExamplePlaying(null)}}
                                        >
                                            <source src={questionData.question.audio} type="audio/mpeg"/>
                                        </audio>
                                    </ListenText>
                                    {/* <QuestionText>
                                        {questionData.question.text}
                                    </QuestionText> */}
                                </>
                                : null
                        }
                        {
                            typeData.type === 'read_aloud' || typeData.type === 'repeat_sentence' ?
                                <>
                                    {
                                        recordingUrl ? 
                                        <ListenText
                                            onClick={() => {
                                                mixpanel.track('Your response Played', {
                                                    // audioSrc: questionData.question.audio, // Tracks the audio source
                                                    questionId: questionData.question.id, // Tracks the question ID
                                                });
                                                {
                                                    recordingPlaying === true?
                                                    handlePauseRecording()
                                                    :
                                                    handlePlayRecording()
                                                }
                                            }}
                                        >
                                            <p>Replay your response</p>
                                            {
                                                recordingPlaying != null ?
                                                recordingPlaying === true ?
                                                <img src={pause} alt="Pause Audio"/>
                                                :
                                                <img src={playDark} alt="Play Audio"/>
                                                :
                                                <img src={speaker} alt="Play Audio"/>
                                            }
                                            <audio 
                                                ref={recordingRef}
                                                onEnded={()=>{setRecordingPlaying(null)}}
                                            >
                                                <source src={recordingUrl} type="audio/mpeg"/>
                                            </audio>
                                        </ListenText>
                                        : null
                                    }
                                </>
                                : null
                        }
                        {
                            typeData.type === 'read_aloud' ?
                            <>
                                <DetailSectionLabel>
                                    <img src={passageIcon}/>
                                    <p>Passage provided</p>
                                </DetailSectionLabel>
                                <QuestionText>
                                    {questionData.question.text}
                                </QuestionText>
                            </>
                            : null
                        }
                        {
                            typeData.type === 'read_aloud' || typeData.type === 'repeat_sentence' ?
                                <>
                                    <WordsMissedLabel>
                                        <img src={wordsMissedIcon}/>
                                        <p>Words you missed</p>
                                    </WordsMissedLabel>
                                    <WordsMissedList>
                                        {
                                            feedback && feedback.wordsMissed ?
                                        feedback.wordsMissed.length > 0 ?
                                        feedback.wordsMissed.map((word, index)=>{
                                            return(
                                                <WordTag key={index}>
                                                        {word}
                                                </WordTag>
                                            )
                                        })
                                        : 
                                        <>
                                            No words missed
                                        </>
                                        : null
                                    }
                                </WordsMissedList>
                            </>
                            : null
                        }
                        {
                            typeData.type === 'describe_image' ?
                            <QuestionImage>
                                <DetailSectionLabel>Question image</DetailSectionLabel>
                                <div>
                                    <img src={questionData.question.image}/>
                                </div>
                            </QuestionImage>
                            : null
                        }
                        {
                            typeData.type === 'retell_lecture' ?
                            <TabContainer>
                                <Tabs
                                    role="tablist"
                                >
                                    <TabButton
                                        role="tab"
                                        id="tab-1"
                                        aria-controls="tabpanel-1"
                                        aria-selected={activeTab === 'transcript'}
                                        className={activeTab === 'transcript' ? 'active' : ''}
                                        onClick={() => {
                                            mixpanel.track('Tab Clicked', {
                                                tabName: 'transcript', // Logs which tab was clicked
                                                tabId: 'tab-1',       // Tracks the tab's ID
                                            });
                                            setActiveTab('transcript'); // Updates the active tab state
                                        }}
                                    >
                                        Your transcript
                                    </TabButton>

                                    <TabButton
                                        role="tab"
                                        id="tab-2"
                                        aria-controls="tabpanel-2"
                                        aria-selected={activeTab === 'replay'}
                                        className={activeTab === 'replay' ? 'active' : ''}
                                        onClick={() => {
                                            mixpanel.track('Tab Clicked', {
                                                tabName: 'Replay Lecture', // Logs which tab was clicked
                                                tabId: 'tab-2',           // Tracks the tab's ID
                                            });
                                            setActiveTab('replay'); // Updates the active tab state
                                        }}
                                    >
                                        <div>
                                            Replay lecture
                                            <img src={playDark} alt="Play Replay" />
                                        </div>
                                    </TabButton>

                                </Tabs>
                                <TabPanel
                                    role="tabpanel"
                                    id="tabpanel-1"
                                    ref={tabPanels[0]}
                                    hidden={activeTab !== 'transcript'}
                                    aria-hidden={activeTab !== 'transcript'}
                                    onTransitionEnd={() => {
                                        if (activeTab === 'transcript') {
                                            mixpanel.track('Tab Panel Viewed', {
                                                tabName: 'Transcript',
                                                panelId: 'tabpanel-1',
                                            });
                                        }
                                    }}
                                >
                                    {
                                        recordingUrl ? 
                                        <ListenText
                                            onClick={() => {
                                                mixpanel.track('Your response Played', {
                                                    // audioSrc: questionData.question.audio, // Tracks the audio source
                                                    questionId: questionData.question.id, // Tracks the question ID
                                                });
                                                {
                                                    recordingPlaying === true?
                                                    handlePauseRecording()
                                                    :
                                                    handlePlayRecording()
                                                }
                                            }}
                                        >
                                            <p>Replay your response</p>
                                            {
                                                recordingPlaying != null ?
                                                recordingPlaying === true ?
                                                <img src={pause} alt="Pause Audio"/>
                                                :
                                                <img src={playDark} alt="Play Audio"/>
                                                :
                                                <img src={speaker} alt="Play Audio"/>
                                            }
                                            <audio 
                                                ref={recordingRef}
                                                onEnded={()=>{setRecordingPlaying(null)}}
                                            >
                                                <source src={recordingUrl} type="audio/mpeg"/>
                                            </audio>
                                        </ListenText>
                                        : null
                                    }
                                    <p style={{marginTop: '40px'}}>"{feedback ? feedback.transcript : ''}"</p>
                                </TabPanel>

                                <TabPanel
                                    role="tabpanel"
                                    id="tabpanel-2"
                                    ref={tabPanels[1]}
                                    hidden={activeTab !== 'replay'}
                                    aria-hidden={activeTab !== 'replay'}
                                    onTransitionEnd={() => {
                                        if (activeTab === 'replay') {
                                            mixpanel.track('Tab Panel Viewed', {
                                                tabName: 'Replay Lecture',
                                                panelId: 'tabpanel-2',
                                            });
                                        }
                                    }}
                                >
                                    <RetellLecture questionData={questionData} />
                                </TabPanel>

                            </TabContainer>
                            : null
                        }
                        {
                            feedback && feedback.contentObjectives ? 
                                <AccordionList>
                                    {Object.keys(feedback.contentObjectives).map((category, index) => (
                                        <AccordionGroup key={index}>
                                            <AccordionButton
                                                onClick={(e) => {
                                                    mixpanel.track('Accordion Toggled', {
                                                        objectiveLabel: feedback.contentObjectives[index].label, // Log the label of the accordion
                                                        passStatus: feedback.contentObjectives[index].pass,     // Log the pass status
                                                        index: index                                            // Log the index of the accordion
                                                    });
                                                    handleAccordion(e.target, index); // Preserve the original functionality
                                                }}
                                            >
                                                <div>
                                                    <div
                                                        className={`expanding-icon ${
                                                            feedback.contentObjectives[index].pass === 'Perfect'
                                                                ? IconGreen
                                                                : feedback.contentObjectives[index].pass === 'Almost'
                                                                    ? IconOrange
                                                                    : IconRed
                                                        }`}
                                                    >
                                                        <img
                                                            src={
                                                                feedback.contentObjectives[index].pass === 'Perfect'
                                                                    ? passIcon
                                                                    : feedback.contentObjectives[index].pass === 'Almost'
                                                                        ? almostIcon
                                                                        : failIcon
                                                            }
                                                            alt={feedback.contentObjectives[index].pass} // Accessibility improvement
                                                        />
                                                        {feedback.contentObjectives[index].pass === 'Perfect' ? (
                                                            <span className={TextColorGreen}>Perfect</span>
                                                        ) : feedback.contentObjectives[index].pass === 'Almost' ? (
                                                            <span className={TextColorOrange}>Almost</span>
                                                        ) : (
                                                            <span className={TextColorRed}>Missed</span>
                                                        )}
                                                    </div>
                                                    <AccordionButtonLabel>{feedback.contentObjectives[index].label}</AccordionButtonLabel>
                                                </div>
                                                <img src={arrowDown} alt="Toggle Accordion" />
                                            </AccordionButton>

                                            <AccordionPanel
                                                ref={accordionPanels[index]}
                                                hidden={true}
                                            >
                                                <p>{getCategoryDescription(feedback.contentObjectives[index].label)}</p>
                                                <PanelMain>
                                                    {
                                                        feedback.contentObjectives[index].label === "Topic" ?
                                                        <>
                                                            {
                                                                feedback.contentObjectives[index].pass === "Perfect" ? 
                                                                <>
                                                                    <span className={TextColorGreen}>What went well</span>
                                                                    <p>To meet this area, you included 1 of the following</p>
                                                                </>
                                                                : 
                                                                <>
                                                                    <span className={TextColorRed}>Points missed</span> 
                                                                    <p>To meet this area, you would have needed to include 1 of the following</p>
                                                                </>
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            {   
                                                                feedback.contentObjectives[index].pass === "Perfect" ? 
                                                                <>
                                                                    <span className={TextColorGreen}>What went well</span>
                                                                    <p>To meet this area, you included 2 or more of the following</p>
                                                                </>
                                                                : 
                                                                <>
                                                                    <span className={feedback.contentObjectives[index].pass === "Almost" ? TextColorOrange : TextColorRed}>Points missed</span> 
                                                                    <p>To meet this area, you would have needed to include any 2 of the following</p>
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                    
                                                    <ul>
                                                        {
                                                            feedback.contentObjectives[index].objectives.map((objective, index)=>{
                                                                return(
                                                                    <li key={index}>{objective.feedback}</li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </PanelMain>
                                            </AccordionPanel>
                                        </AccordionGroup>
                                    ))}
                                </AccordionList>
                            : null
                        }
                        
                    </DetailBody>
                </ScoreContainer>
            }
        </>
    )
}

export default ContentDetail


const bounce = keyframes`
    0%,30%{
        transform:translate(0px);
        background: var(--color-monochrome-15);
    }
    50%  {
        transform:translate(0, -8px);
        background: var(--color-brand-primary-50);
    }
    100% {
        transform:translate(0px);
        background: var(--color-monochrome-15);
    }
`

const LoadingContainer = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    height:100%;
`

const LoaderAnimation = styled.div`
    margin:16px;
    position: relative;
    float:left;
    // width: 62px;
    height: 32px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    gap:6px;
    padding: 0 16px;
    box-sizing:border-box;
    span{
        display:block;
        height:8px;
        width:8px;
        border-radius:8px;
        background: var(--color-monochrome-15);
        animation: ${bounce} 2s infinite ease;
        &.first{
            animation-delay:200ms;
        }
        &.middle{
            animation-delay:500ms;
        }
        &.last{
            animation-delay:800ms;
        }
    }
`

const ScoreContainer = styled.div`
    margin-left: -16px;
    width: calc(100% + 32px);
    padding:0 16px;
    flex:1;
`

const DetailHeader = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin-bottom:20px;
    margin-top:8px;
`

const ScoreBreakdownChartRadial = styled.div`
    position:relative;
`

const CustomLabelRadial = styled.span`
    display:block;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    margin-top:12px;
    color: #000;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
`

const DetailBody = styled.div`

`

const DetailSectionLabel = styled.div`
    display:flex;
    align-items:center;
    gap:4px;
    margin-bottom:12px;
    p{
        margin:0;
        color: var(--color-text-tertiary);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px
    }
`

const ListenText = styled.button`
    display:flex;
    align-items:center;
    gap:24px;
    margin-bottom:8px;
    padding: 0;
    p{
        margin: 0;
        color: #000;
        font-size: 20px;
        font-weight: 400;
        line-height: 160%; 
    }
    button{
        width:24px;
        height:24px;
    }
`

const QuestionText = styled.p`
    border-radius: 10px;
    background: var(--primary-colours-vet-blue-1-f-0-f-6-ff);
    padding:16px;
    font-size: var(--font-size-b1);
    line-height: var(--font-line-height-b1);
`

const QuestionImage = styled.div`
    width:100%;
    div{
        width:100%;
        cursor:pointer;
        img{
            width:100%;
            object-fit:contain;
        }
    }
`

const WordsMissedLabel = styled.div`
    display:flex;
    align-items:center;
    gap:4px;
    margin-bottom:20px;
    margin-top:20px;
    p{
        margin:0;
        color: var(--color-text-info);
        font-size: 18px;
        font-weight: 400;
        line-height: 24px
    }
`

const WordsMissedList = styled.div`
    display:flex;
    align-items:center;
    flex-wrap:wrap;
    gap:12px;
    padding-bottom:30px;
`

const WordTag = styled.span`
    color: var(--color-text-secondary);
    border-radius: 80px;
    border: 1px solid var(--color-stroke-container-primary);
    background: var(--color-fill-section-primary);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding:4px 20px;
`

const AccordionList = styled.div`
    margin-top:26px;
`

const AccordionGroup = styled.div`
    border-radius: 12px;
    border: 1px solid var(--color-stroke-container-secondary);
    background: var(--color-fill-container-primary);
    box-shadow: var(--elevation-x-none, 0px) var(--elevation-y-sm, 2px) var(--elevation-blur-xs, 4px) 0px var(--elevation-default, rgba(8, 9, 9, 0.08));
    padding:16px;
    margin-bottom:8px;
`

const AccordionButton = styled.button`
    display:flex;
    align-items:center;
    justify-content:space-between;
    width:100%;
    padding:0;
    div, img, span{
        pointer-events:none;
    }
    div{
        display:flex;
        align-items:center;
        gap:14px;
    }
    .expanding-icon{
        border-radius:80px;
        width:20px;
        height:20px;
        display:flex;
        align-items:center;
        justify-content:center;
        span{
            display:none;
        }
    }
    &[aria-expanded="true"]{
        &>img{
            transform: rotate(180deg);
        }
        &>div{
            flex-direction: column;
            align-items: flex-start;
        }
        .expanding-icon{
            width:unset;
            padding: 2px 12px;
            img{
                display:none;
            }
            span{
                display:block;
            }
        }
    }    
`

const IconRed = css`
    background: var(--color-fill-error);
`

const IconGreen = css`
    background: var(--color-fill-success);
`

const IconOrange = css`
    background: var(--color-fill-warning);
`

const AccordionButtonLabel = styled.span`
    color: #000;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
`

const AccordionPanel = styled.div`
    &>p{
        color:#000;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }
`

const PanelMain = styled.div`
    border-radius: 8px;
    background: var(--color-fill-section-secondary, #FAFAFA);
    padding:16px;
    margin-top:12px;
    span{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }
    p{
        margin: 8px 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }
    ul{
        padding-inline-start:24px;
        margin-block-end: 0;
        li{
            list-style-type: disc;
            color: #000;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }
    }
`

const TabContainer = styled.div`

`

const Tabs = styled.div`
    display:flex;
    align-items:center;
    gap:16px;
    margin-bottom:20px;
`

const TabButton = styled.button`
    width:150;
    height:60px;
    display:flex;
    align-items:center;
    justify-content:center;
    color: var(--color-text-tertiary);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    div{
        display:flex;
        align-items:center;
        justify-content:center;
        gap:8px;
    }
    &.active{
        color: var(--color-text-primary);
        font-weight: 600;
        border-bottom: 4px solid var(--color-stroke-interactive-focus);
    }
`
const TabPanel = styled.div`
    p{
        color: var(--color-text-primary);
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
    }
`