import {useEffect, useState} from 'react'
import styled from '@emotion/styled'
import { cx, css } from '@emotion/css'
import { Modal, Overlay, PrimaryButton, InputGroup, Label, InputContainer } from "../global_styles/global_styles"
import {saveGoalScore} from "../util/saveGoalScore"
import { setUserData } from '../redux/profileSlice';
import FocusTrap from "focus-trap-react"
import { useDispatch } from 'react-redux';
import mixpanel from "mixpanel-browser";

//images
import arrowDown from "../images/arrow-down.svg"

function GoalScore({hideGoalScore}) {

    const dispatch = useDispatch()

    const [currentScore, setCurrentScore] = useState(null)
    const [goalScore, setGoalScore] = useState(null)
    const [showDropDownOne, setShowDropDownOne] = useState(false)
    const [showDropDownTwo, setShowDropDownTwo] = useState(false)
    const [continueDisabled, setContinueDisabled] = useState(true)

    const handleContinue = () => {
        let score = {
            currentScore: currentScore,
            goalScore: goalScore
        }
        saveGoalScore(score)
        .then((response)=>response.json())
        .then(data=>{
            if(data.username){
                // console.log(data)
                localStorage.setItem('profile', JSON.stringify(data));
                dispatch(setUserData(data))
                hideGoalScore()
            }
        })
    }

    const handleDropDownOne = () => {
        setShowDropDownOne(!showDropDownOne)
        if(showDropDownTwo){
            setShowDropDownTwo(false)
        }
    }

    const handleDropDownTwo = () => {
        setShowDropDownTwo(!showDropDownTwo)
        if(showDropDownOne){
            setShowDropDownOne(false)
        }
    }

    const handleCurrentScore = (score) => {
        setCurrentScore(score)
        handleDropDownOne()
    }

    const handleGoalScore = (score) => {
        setGoalScore(score)
        handleDropDownTwo()
    }

    useEffect(()=>{
        if(currentScore && goalScore){
            setContinueDisabled(false)
        }
    },[currentScore, goalScore])

    return (
        <>
            <FocusTrap>
                <div className={css(Modal, CustomModal)}>
                    <ModalSpan>Welcome!</ModalSpan>
                    <ModalH1>Tell us about your score</ModalH1>
                    <InputGroup>
                        <Label>What level are you currently?</Label>
                        <SelectContainer>
                            <CustomSelect
                                onClick={() => {
                                    mixpanel.track('Dropdown Opened', {
                                        score: currentScore || 'No Score Selected', // Track the current score or indicate no selection
                                    });
                                    handleDropDownOne(); // Preserve the original dropdown functionality
                                }}
                                score={currentScore}
                            >
                                <span>{currentScore ? currentScore : 'Select option...'}</span>
                                <img src={arrowDown} alt="Open Dropdown"/>
                            </CustomSelect>

                            {
                                showDropDownOne ?
                                    <OptionsDropDown>
                                        <ul>
                                            <li
                                                onClick={() => {
                                                    mixpanel.track('Score Selected', {
                                                        score: '85-90',
                                                        CEFR: 'C2',
                                                    });
                                                    handleCurrentScore('85-90');
                                                }}
                                            >
                                                <span>85-90</span>
                                                <span>CEFR Equivalent: C2</span>
                                            </li>
                                            <li
                                                onClick={() => {
                                                    mixpanel.track('Score Selected', {
                                                        score: '76-84',
                                                        CEFR: 'C1',
                                                    });
                                                    handleCurrentScore('76-84');
                                                }}
                                            >
                                                <span>76-84</span>
                                                <span>CEFR Equivalent: C1</span>
                                            </li>
                                            <li
                                                onClick={() => {
                                                    mixpanel.track('Score Selected', {
                                                        score: '59-75',
                                                        CEFR: 'B2',
                                                    });
                                                    handleCurrentScore('59-75');
                                                }}
                                            >
                                                <span>59-75</span>
                                                <span>CEFR Equivalent: B2</span>
                                            </li>
                                            <li
                                                onClick={() => {
                                                    mixpanel.track('Score Selected', {
                                                        score: '43-58',
                                                        CEFR: 'B2',
                                                    });
                                                    handleCurrentScore('43-58');
                                                }}
                                            >
                                                <span>43-58</span>
                                                <span>CEFR Equivalent: B2</span>
                                            </li>
                                            <li
                                                onClick={() => {
                                                    mixpanel.track('Score Selected', {
                                                        score: '30-42',
                                                        CEFR: 'A2',
                                                    });
                                                    handleCurrentScore('30-42');
                                                }}
                                            >
                                                <span>30-42</span>
                                                <span>CEFR Equivalent: A2</span>
                                            </li>
                                            <li
                                                onClick={() => {
                                                    mixpanel.track('Score Selected', {
                                                        score: '10-29',
                                                        CEFR: 'A1',
                                                    });
                                                    handleCurrentScore('10-29');
                                                }}
                                            >
                                                <span>10-29</span>
                                                <span>CEFR Equivalent: A1</span>
                                            </li>
                                            <li
                                                onClick={() => {
                                                    mixpanel.track('Score Selected', {
                                                        score: "I'm not sure",
                                                        CEFR: 'Unknown',
                                                    });
                                                    handleCurrentScore("I'm not sure");
                                                }}
                                            >
                                                <span>I'm not sure</span>
                                            </li>

                                        </ul>
                                    </OptionsDropDown>
                                    : null
                            }
                        </SelectContainer>
                    </InputGroup>
                    <InputGroup>
                        <Label>What score are you aiming for?</Label>
                        <SelectContainer>
                            <CustomSelect
                                onClick={() => {
                                    mixpanel.track('Dropdown Opened', {
                                        dropdownType: 'Goal Score', // Identifies the dropdown type
                                        currentSelection: goalScore || 'No Score Selected', // Tracks the current selection
                                    });
                                    handleDropDownTwo(); // Calls the original dropdown handler
                                }}
                                score={goalScore}
                            >
                                <span>{goalScore ? goalScore : 'Select option...'}</span>
                                <img src={arrowDown} alt="Open Dropdown"/>
                            </CustomSelect>

                            {
                                showDropDownTwo ?
                                    <OptionsDropDown className={OptionsTwo}>
                                        <ul>
                                            <li
                                                onClick={() => {
                                                    mixpanel.track('Goal Score Selected', {
                                                        score: '85-90',
                                                        CEFR: 'C2',
                                                    });
                                                    handleGoalScore('85-90');
                                                }}
                                            >
                                                <span>85-90</span>
                                                <span>CEFR Equivalent: C2</span>
                                            </li>
                                            <li
                                                onClick={() => {
                                                    mixpanel.track('Goal Score Selected', {
                                                        score: '76-84',
                                                        CEFR: 'C1',
                                                    });
                                                    handleGoalScore('76-84');
                                                }}
                                            >
                                                <span>76-84</span>
                                                <span>CEFR Equivalent: C1</span>
                                            </li>
                                            <li
                                                onClick={() => {
                                                    mixpanel.track('Goal Score Selected', {
                                                        score: '59-75',
                                                        CEFR: 'B2',
                                                    });
                                                    handleGoalScore('59-75');
                                                }}
                                            >
                                                <span>59-75</span>
                                                <span>CEFR Equivalent: B2</span>
                                            </li>
                                            <li
                                                onClick={() => {
                                                    mixpanel.track('Goal Score Selected', {
                                                        score: '43-58',
                                                        CEFR: 'B2',
                                                    });
                                                    handleGoalScore('43-58');
                                                }}
                                            >
                                                <span>43-58</span>
                                                <span>CEFR Equivalent: B2</span>
                                            </li>
                                            <li
                                                onClick={() => {
                                                    mixpanel.track('Goal Score Selected', {
                                                        score: '30-42',
                                                        CEFR: 'A2',
                                                    });
                                                    handleGoalScore('30-42');
                                                }}
                                            >
                                                <span>30-42</span>
                                                <span>CEFR Equivalent: A2</span>
                                            </li>
                                            <li
                                                onClick={() => {
                                                    mixpanel.track('Goal Score Selected', {
                                                        score: '10-29',
                                                        CEFR: 'A1',
                                                    });
                                                    handleGoalScore('10-29');
                                                }}
                                            >
                                                <span>10-29</span>
                                                <span>CEFR Equivalent: A1</span>
                                            </li>
                                            <li
                                                onClick={() => {
                                                    mixpanel.track('Goal Score Selected', {
                                                        score: "I'm not sure",
                                                        CEFR: 'Unknown',
                                                    });
                                                    handleGoalScore("I'm not sure");
                                                }}
                                            >
                                                <span>I'm not sure</span>
                                            </li>

                                        </ul>
                                    </OptionsDropDown>
                                    : null
                            }
                        </SelectContainer>
                    </InputGroup>
                    <button
                        className={PrimaryButton}
                        onClick={() => {
                            mixpanel.track('Button Clicked', {
                                buttonText: 'Get started', // Logs the button text
                                buttonState: continueDisabled ? 'Disabled' : 'Enabled', // Tracks if the button was disabled or enabled
                            });
                            handleContinue(); // Calls the original handler
                        }}
                        disabled={continueDisabled}
                    >
                        Get started
                    </button>

                </div>
            </FocusTrap>
            <div className={Overlay}></div>
        </>
    )
}

export default GoalScore

const CustomModal = css`
    @media(max-width: 430px){
        transform:translate(-50%, -70%);
    }
`

const ModalSpan = styled.span`
    margin: 0;
    color: var(--color-text-primary, #101112);
    font-size: 16px;
    font-weight: 400;
`

const ModalH1 = styled.h1`
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin: 25px 0;
    text-align: center;
    width: 100%;
`

const SelectContainer = styled.div`
    position: relative;
`

const CustomSelect = styled.button`
    width: 100%;
    height: 48px;
    border-radius: 8px;
    border: 1px solid var(--color-stroke-interactive-input-field-default);
    background: var(--color-fill-interactive-input-field-default);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    cursor: pointer;

    span {
        color: ${props => props.score ? 'var(--color-text-primary)' : 'var(--color-text-tertiary)'};
        font-size: 16px;
        font-weight: ${props => props.score ? '600' : '400'};
        line-height: 24px;
    }
`

const OptionsDropDown = styled.div`
    border-radius: 8px;
    border: 1px solid var(--color-stroke-interactive-default);
    background: white;
    box-shadow: var(--elevation-x-none, 0px) var(--elevation-y-sm, 2px) var(--elevation-blur-xs, 4px) 0px var(--elevation-default, rgba(8, 9, 9, 0.08));
    padding:8px 20px;
    height:220px;
    width:100%;
    overflow:auto;
    position:absolute;
    top:54px;
    left:0;
    z-index:1003;
    ul{
        li{
            padding:12px 0;
            cursor:pointer;
            span{
                display:block;
                &:first-of-type{
                    color: var(--color-text-primary);
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                }
            }
        }
    }
`

const OptionsTwo = css`
    // top:-100px;
`