import { createContext, useContext, useEffect, useState, useRef } from "react";
import Cookies from 'js-cookie';
import { useSelector, useDispatch } from 'react-redux';
import { savePAFFeedback } from "./savePAFFeedback";
import { getUserAttempts } from "./getUserAttempts";
import { setUserAttempts } from "../redux/userAttemptsSlice";
import {content_objectives} from "../data/content_objectives"
import { getContentFeedback } from "./getContentFeedback";
import {saveContentFeedback} from "./saveContentFeedback"
import WS_URL from "./WS_URL";

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {

  const dispatch = useDispatch()

  let feedbackId, questionId, typeData;

  const webSocketState = useSelector(state => state.webSocketState)

  const [ws, setWs] = useState(null);
  const [open, setOpen] = useState(false)

  useEffect(()=>{
    if(webSocketState.open === 'open'){
      setOpen(true)
    }else if(webSocketState === 'closed'){
      setOpen(false)
    }
  },[webSocketState])

  useEffect(() => {
    if (open && !ws) {
        const session_id = Cookies.get('session_id'); 
        const socket = new WebSocket(`${WS_URL}?session_id=${session_id}`);
        setWs(socket);

        socket.onopen = () => {
            // console.log("Connected to node WebSocket")
            setTimeout(()=>{ //need an artifical delay here or message won't send even if socket.readyState === WebSocket.OPEN
              //I think this is ok though because there is still a 3 second delay until recording can actually be started
              if (socket.readyState === WebSocket.OPEN) {
                if(webSocketState.referenceText.length > 0){
                    let text = webSocketState.referenceText.toLowerCase()
                    let parsedReferenceText = text.replace(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g, ""); //remove punctuation
                    socket.send(JSON.stringify({startStreaming: true, typeData: webSocketState.typeData, referenceText: parsedReferenceText.replaceAll(" ", "+")}), { binary: false});
                }else{
                    socket.send(JSON.stringify({startStreaming: true, typeData: webSocketState.typeData,}), { binary: false});
                }
              }
            },1000)
        };

        socket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            // console.log(message)
            // console.log(message.error)
            if(message.error){
                window.dispatchEvent(new CustomEvent("webSocketProviderDone", {detail: {scoreAvailable: false}}));
                return
            }else{
              feedbackId = message.feedbackId
              questionId = message.questionId
              typeData = message.typeData
              const pafEvaluation = message.pafEvaluation
              //save the raw P&F feedback to mongo database
              savePAFFeedback(message)
              .then(()=>{
                if(typeData.type === 'short_question'){
                  window.dispatchEvent(new CustomEvent("webSocketProviderDone", {detail: {scoreAvailable: true}}));
                }else if(typeData.type != 'short_question'){
                  //get the transcript from the evaluation
                  let transcript = pafEvaluation.detailed.displayText
                  let data;
                  if(content_objectives[questionId]){
                    let objectives = []
                    content_objectives[questionId].forEach(ob => {
                        objectives.push(ob.objective)
                    })
                    data = {
                      scoringCategory: 'open_ended',
                      text: transcript,
                      objectives: objectives
                    }
                  }else{
                    data = {
                      scoringCategory: 'closed_ended',
                      text: transcript,
                      model_sequence: webSocketState.referenceText
                    }
                  }
                  //send to the content api
                  getContentFeedback(data)
                  .then(response=>response.json())
                  .then(data=>{
                      let contentData = {
                        feedbackId: feedbackId,
                        contentEvaluation: data
                      }
                      //save content feedback in mongo database
                      saveContentFeedback(contentData)
                      .then(()=>{
                        window.dispatchEvent(new CustomEvent("webSocketProviderDone", {detail: {scoreAvailable: true}}));
                      })
                  })
                }
              })
          }
        };
          
        // socket.onclose = () => console.log("Disconnected from node WebSocket");

        socket.onerror = (error) => {
            console.error("WebSocket error:", error);
        };

        setWs(socket);

        return () => socket.close(); // Close WebSocket on cleanup

      } else if (!open && ws) {
        ws.close();
        setWs(null);
      }
  }, [open]);

  return (
    <WebSocketContext.Provider value={ws}>
      {children}
    </WebSocketContext.Provider>
  );
};

// Hook to use WebSocket in any component
export const useWebSocket = () => useContext(WebSocketContext);