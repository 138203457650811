export const getBreakdownValue = (label) => {
    if(label === "Low"){
        return 33
    }else if(label === "Mid"){
        return 66
    }else if(label === "High"){
        return 100
    }else{
        return 0
    }
}