import { createSlice } from "@reduxjs/toolkit";

const userAttemptsSlice = createSlice({
    name: "userAttempts",
    initialState: [],
    reducers : {
        setUserAttempts : (state, action) => {
            let newState = [...state]
            newState = action.payload
            // console.log(newState)
            return newState
          }
    }
});
  
export const { setUserAttempts } = userAttemptsSlice.actions;

export default userAttemptsSlice.reducer;