export const content_objectives = {
    9:[
        {
            objective: "This text mentions different modes of travel to school.",
            feedback: "Different modes of travel to school are mentioned.",
            category: "Labels",
        },
        {
            objective: "This text mentions that data is presented for urban children in the UK and India.",
            feedback: "Data is presented for urban children in the UK and India.",
            category: "Labels",
        },
        {
            objective: "This text mentions that travel modes include walking, cycling, buses, and other forms.",
            feedback: "Travel modes include walking, cycling, buses, and other forms.",
            category: "Labels",
        },
        {
            objective: "This text mentions that percentages represent the proportion of children using each travel mode.",
            feedback: "Percentages represent the proportion of children using each travel mode.",
            category: "Labels",
        },
        {
            objective: "This text mentions that walking is the most common travel mode in both countries.",
            feedback: "Walking is the most common travel mode in both countries.",
            category: "Key features",
        },
        {
            objective: "This text mentions that cars are heavily used in the UK.",
            feedback: "Cars are heavily used in the UK.",
            category: "Key features",
        },
        {
            objective: "This text mentions that cars are rarely used in India.",
            feedback: "Cars are rarely used in India.",
            category: "Key features",
        },
        {
            objective: "This text mentions that motorbikes and scooters are used exclusively in India.",
            feedback: "Motorbikes and scooters are used exclusively in India.",
            category: "Key features",
        },
        {
            objective: "This text mentions that train/tram/metro usage is negligible in both countries",
            feedback: "Train/tram/metro usage is negligible in both countries.",
            category: "Key features",
        },
        {
            objective: "This text mentions that 57% of children in India walk to school.",
            feedback: "57% of children in India walk to school.",
            category: "Numbers and specifics",
        },
        {
            objective: "This text mentions that 37% of children in India walk to school.",
            feedback: "37% of children in India walk to school.",
            category: "Numbers and specifics",
        },
        {
            objective: "This text mentions that bicycle use is 6% in India.",
            feedback: "Bicycle use is 6% in India.",
            category: "Numbers and specifics",
        },
        {
            objective: "This text mentions that bicycle use is 2% in the UK.",
            feedback: "Bicycle use is 2% in the UK.",
            category: "Numbers and specifics",
        },
        {
            objective: "This text mentions that bus use is 22% in the UK.",
            feedback: "Bus use is 22% in the UK.",
            category: "Numbers and specifics",
        },
        {
            objective: "This text mentions that bus use is 13% in India.",
            feedback: "Bus use is 13% in India.",
            category: "Numbers and specifics",
        },
        {
            objective: "This text mentions that Train/Tram/Metro use is 2% in the UK.",
            feedback: "Train/Tram/Metro use is 2% in the UK.",
            category: "Numbers and specifics",
        },
        {
            objective: "This text mentions that Train/Tram/Metro use is 0% in India.",
            feedback: "Train/Tram/Metro use is 0% in India.",
            category: "Numbers and specifics",
        },
        {
            objective: "This text mentions that Car travel is 35% in the UK.",
            feedback: "Car travel is 35% in the UK.",
            category: "Numbers and specifics",
        },
        {
            objective: "This text mentions that Car travel is 5% in India.",
            feedback: "Car travel is 5% in India.",
            category: "Numbers and specifics",
        },
        {
            objective: "This text mentions that Motorbike/Scooter use is 0% in the UK.",
            feedback: "Motorbike/Scooter use is 0% in the UK.",
            category: "Numbers and specifics",
        },
        {
            objective: "This text mentions that Motorbike/Scooter use is 9% in India.",
            feedback: "Motorbike/Scooter use is 9% in India.",
            category: "Numbers and specifics",
        },
        {
            objective: "This text mentions that other forms of travel account for 10% in India.",
            feedback: "Other forms of travel account for 10% in India.",
            category: "Numbers and specifics",
        },
        {
            objective: "This text mentions that other forms of travel account for 2% in the UK.",
            feedback: "Other forms of travel account for 2% in the UK.",
            category: "Numbers and specifics",
        },
        {
            objective: "This text mentions that a significantly higher percentage of children walk to school in India compared to the UK.",
            feedback: "A significantly higher percentage of children walk to school in India compared to the UK.",
            category: "Use comparisons",
        },
        {
            objective: "This text mentions that cars are the second most popular mode in the UK but are much less used in India.",
            feedback: "Cars are the second most popular mode in the UK but are much less used in India.",
            category: "Use comparisons",
        },
        {
            objective: "This text mentions that bicycle use is three times higher in India than in the UK.",
            feedback: "Bicycle use is three times higher in India than in the UK.",
            category: "Use comparisons",
        },
        {
            objective: "This text mentions that motorbikes and scooters are exclusively used in India, unlike the UK.",
            feedback: "Motorbikes and scooters are exclusively used in India, unlike the UK.",
            category: "Use comparisons",
        }
    ],
    10:[
        {
            objective: "This text mentions that four age groups are represented.",
            feedback: "Four age groups are represented.",
            category: "Labels"
        },
        {
            objective: "This text mentions that the year 2020 is represented by orange color.",
            feedback: "The year 2020 is represented by orange color.",
            category: "Labels"
        },
        {
            objective: "This text mentions that the year 2010 is represented by blue color.",
            feedback: "The year 2010 is represented by blue color.",
            category: "Labels"
        },
        {
            objective: "This text mentions that the years 2010 and 2020 are compared.",
            feedback: "The years 2010 and 2020 are compared.",
            category: "Labels"
        },
        {
            objective: "This text mentions that In the age group 0-20, the population decreased.",
            feedback: "In the age group 0-20, the population decreased.",
            category: "Key features"
        },
        {
            objective: "This text mentions that In the age group 21-40, the population increased.",
            feedback: "In the age group 21-40, the population increased.",
            category: "Key features"
        },
        {
            objective: "This text mentions that In the age group 41-60, the population increased.",
            feedback: "In the age group 41-60, the population increased.",
            category: "Key features"
        },
        {
            objective: "This text mentions that In the age group 61 and over, the population increased.",
            feedback: "In the age group 61 and over, the population increased.",
            category: "Key features"
        },
        {
            objective: "This text mentions that The age group 41-60 had the highest population in both 2010 and 2020.",
            feedback: "The age group 41-60 had the highest population in both 2010 and 2020.",
            category: "Use comparisons"
        },
        {
            objective: "This text mentions that The age group 0-20 is the only one that experienced a population decrease over the decade.",
            feedback: "The age group 0-20 is the only one that experienced a population decrease over the decade.",
            category: "Use comparisons"
        },
        {
            objective: "This text mentions that The most significant population growth was in the age group 61 and over.",
            feedback: "The most significant population growth was in the age group 61 and over.",
            category: "Use comparisons"
        },
        {
            objective: "This text mentions that The y-axis represents population numbers ranging from 0 to 80,000.",
            feedback: "The y-axis represents population numbers ranging from 0 to 80,000.",
            category: "Labels"
        },
        {
            objective: "This text mentions that The x-axis represents different age groups in the population.",
            feedback: "The x-axis represents different age groups in the population.",
            category: "Labels"
        },
        {
            objective: "This text mentions that graph shows comparison of population numbers in a town by age group.",
            feedback: "The graph shows a comparison of population numbers in a town by age group.",
            category: "Labels"
        },
        {
            objective: "This text mentions that age group 0-20 went from 40,000 in 2010 to 30,000 in 2020.",
            feedback: "The age group 0-20 went from 40,000 in 2010 to 30,000 in 2020.",
            category: "Numbers and specifics"
        },
        {
            objective: "This text mentions that age group 21-40 went from 32,000 in 2010 to 35,000 in 2020.",
            feedback: "The age group 21-40 went from 32,000 in 2010 to 35,000 in 2020.",
            category: "Numbers and specifics"
        },
        {
            objective: "This text mentions that age group 41-60 went from 60,000 in 2010 to 75,000 in 2020.",
            feedback: "The age group 41-60 went from 60,000 in 2010 to 75,000 in 2020.",
            category: "Numbers and specifics"
        },
        {
            objective: "This text mentions that age group 61 and over, went from 28,000 in 2010 to 43,000 in 2020.",
            feedback: "The age group 61 and over went from 28,000 in 2010 to 43,000 in 2020.",
            category: "Numbers and specifics"
        }
    ],
    11:[
        {
            objective: "This text mentions that graph presents numbers of sightings of dolphins and whales in the bay.",
            feedback: "The graph presents numbers of sightings of dolphins and whales in the bay.",
            category: "Labels",
        },
        {
            objective: "This text mentions that the x-axis represents the years.",
            feedback: "The x-axis represents the years.",
            category: "Labels",
        },
        {
            objective: "This text mentions that the y-axis shows the number of sightings.",
            feedback: "The y-axis shows the number of sightings.",
            category: "Labels",
        },
        {
            objective: "This text mentions two categories: dolphins and whales.",
            feedback: "There are two categories: dolphins and whales.",
            category: "Labels",
        },
        {
            objective: "This text mentions that blue bars represent dolphin sightings",
            feedback: "Blue bars represent dolphin sightings.",
            category: "Labels",
        },
        {
            objective: "This text mentions that orange bars represent whale sightings",
            feedback: "Orange bars represent whale sightings.",
            category: "Labels",
        },
        {
            objective: "This text mentions that the highest whale sightings occurred in 2020.",
            feedback: "The highest whale sightings occurred in 2020.",
            category: "Key features",
        },
        {
            objective: "This text mentions that the highest dolphin sightings occurred in 2020.",
            feedback: "The highest dolphin sightings occurred in 2020.",
            category: "Key features",
        },
        {
            objective: "This text mentions that the lowest whale sightings occurred in 2018 and 2019.",
            feedback: "The lowest whale sightings occurred in 2018 and 2019.",
            category: "Key features",
        },
        {
            objective: "This text mentions that the lowest dolphin sightings occurred in 2017 and 2018.",
            feedback: "The lowest dolphin sightings occurred in 2017 and 2018.",
            category: "Key features",
        },
        {
            objective: "This text mentions that whale sightings were higher than dolphin sightings in all years.",
            feedback: "Whale sightings were higher than dolphin sightings in all years.",
            category: "Key features",
        },
        {
            objective: "This text mentions that whale sightings increased steadily from 2018 to 2020.",
            feedback: "Whale sightings increased steadily from 2018 to 2020.",
            category: "Key features",
        },
        {
            objective: "This text mentions that dolphin sightings increased steadily from 2018 to 2020.",
            feedback: "Dolphin sightings increased steadily from 2018 to 2020.",
            category: "Key features",
        },
        {
            objective: "This text mentions there were 522 whale sightings in 2017.",
            feedback: "There were 522 whale sightings in 2017.",
            category: "Numbers and specifics",
        },
        {
            objective: "This text mentions there were 203 dolphin sightings in 2017.",
            feedback: "There were 203 dolphin sightings in 2017.",
            category: "Numbers and specifics",
        },
        {
            objective: "This text mentions there were 253 whale sightings in 2018.",
            feedback: "There were 253 whale sightings in 2018.",
            category: "Numbers and specifics",
        },
        {
            objective: "This text mentions there were 211 dolphin sightings in 2018.",
            feedback: "There were 211 dolphin sightings in 2018.",
            category: "Numbers and specifics",
        },
        {
            objective: "This text mentions there were 247 whale sightings in 2019.",
            feedback: "There were 247 whale sightings in 2019.",
            category: "Numbers and specifics",
        },
        {
            objective: "This text mentions there were 409 dolphin sightings in 2019.",
            feedback: "There were 409 dolphin sightings in 2019.",
            category: "Numbers and specifics",
        },
        {
            objective: "This text mentions there were 707 whale sightings in 2020.",
            feedback: "There were 707 whale sightings in 2020.",
            category: "Numbers and specifics",
        },
        {
            objective: "This text mentions there were 513 dolphin sightings in 2020.",
            feedback: "There were 513 dolphin sightings in 2020.",
            category: "Numbers and specifics",
        },
        {
            objective: "This text mentions that in 2017 there were more whale sightings than dolphin sightings.",
            feedback: "In 2017, there were more whale sightings than dolphin sightings.",
            category: "Use comparisons",
        },
        {
            objective: "This text mentions that in 2018 there were more whale sightings than dolphin sightings.",
            feedback: "In 2018, there were more whale sightings than dolphin sightings.",
            category: "Use comparisons",
        },
        {
            objective: "This text mentions that in 2019 there were less whale sightings than dolphin sightings.",
            feedback: "In 2019, there were fewer whale sightings than dolphin sightings.",
            category: "Use comparisons",
        },
        {
            objective: "This text mentions that in 2020 there were more whale sightings than dolphin sightings.",
            feedback: "In 2020, there were more whale sightings than dolphin sightings.",
            category: "Use comparisons",
        },
    ],
    12:[
        {
            objective: "This text mentions that the graph presents reading habits in the city of Winstone.",
            feedback: "The graph presents reading habits in the city of Winstone.",
            category: "Labels",
        },
        {
            objective: "This text mentions that the x-axis represents different reading activities.",
            feedback: "The x-axis represents different reading activities.",
            category: "Labels",
        },
        {
            objective: "This text mentions that the y-axis shows the average hours per week.",
            feedback: "The y-axis shows the average hours per week.",
            category: "Labels",
        },
        {
            objective: "This text mentions three categories: students, working professionals, and retired adults.",
            feedback: "There are three categories: students, working professionals, and retired adults.",
            category: "Labels",
        },
        {
            objective: "This text mentions that blue bars represent students.",
            feedback: "Blue bars represent students.",
            category: "Labels",
        },
        {
            objective: "This text mentions that orange bars represent working professionals.",
            feedback: "Orange bars represent working professionals.",
            category: "Labels",
        },
        {
            objective: "This text mentions that gray bars represent retired adults.",
            feedback: "Gray bars represent retired adults.",
            category: "Labels",
        },
        {
            objective: "This text mentions that students spend the most time reading social media.",
            feedback: "Students spend the most time reading social media.",
            category: "Key features",
        },
        {
            objective: "This text mentions that retired adults spend the most time reading books.",
            feedback: "Retired adults spend the most time reading books.",
            category: "Key features",
        },
        {
            objective: "This text mentions that working professionals spend the most time reading newspapers.",
            feedback: "Working professionals spend the most time reading newspapers.",
            category: "Key features",
        },
        {
            objective: "This text mentions that students spend the least time reading newspapers.",
            feedback: "Students spend the least time reading newspapers.",
            category: "Key features",
        },
        {
            objective: "This text mentions that retired adults spend the least time reading social media.",
            feedback: "Retired adults spend the least time reading social media.",
            category: "Key features",
        },
        {
            objective: "This text mentions that students spend 7 hours per week reading social media.",
            feedback: "Students spend 7 hours per week reading social media.",
            category: "Numbers and specifics",
        },
        {
            objective: "This text mentions that working professionals spend 2 hours per week reading social media.",
            feedback: "Working professionals spend 2 hours per week reading social media.",
            category: "Numbers and specifics",
        },
        {
            objective: "This text mentions that retired adults spend 3 hours per week reading social media.",
            feedback: "Retired adults spend 3 hours per week reading social media.",
            category: "Numbers and specifics",
        },
        {
            objective: "This text mentions that students spend 3 hours per week reading books.",
            feedback: "Students spend 3 hours per week reading books.",
            category: "Numbers and specifics",
        },
        {
            objective: "This text mentions that working professionals spend 4 hours per week reading books.",
            feedback: "Working professionals spend 4 hours per week reading books.",
            category: "Numbers and specifics",
        },
        {
            objective: "This text mentions that retired adults spend 8 hours per week reading books.",
            feedback: "Retired adults spend 8 hours per week reading books.",
            category: "Numbers and specifics",
        },
        {
            objective: "This text mentions that students spend 1 hour per week reading newspapers.",
            feedback: "Students spend 1 hour per week reading newspapers.",
            category: "Numbers and specifics",
        },
        {
            objective: "This text mentions that working professionals spend 2 hours per week reading newspapers.",
            feedback: "Working professionals spend 2 hours per week reading newspapers.",
            category: "Numbers and specifics",
        },
        {
            objective: "This text mentions that retired adults spend 1 hours per week reading newspapers.",
            feedback: "Retired adults spend 1 hour per week reading newspapers.",
            category: "Numbers and specifics",
        },
        {
            objective: "This text mentions that students spend 2 hour per week reading blogs.",
            feedback: "Students spend 2 hours per week reading blogs.",
            category: "Numbers and specifics",
        },
        {
            objective: "This text mentions that working professionals spend 1 hours per week reading blogs.",
            feedback: "Working professionals spend 1 hour per week reading blogs.",
            category: "Numbers and specifics",
        },
        {
            objective: "This text mentions that retired adults spend 1.5 hours per week reading blogs.",
            feedback: "Retired adults spend 1.5 hours per week reading blogs.",
            category: "Numbers and specifics",
        },
        {
            objective: "This text mentions that students spend more time reading social media than working professionals and retired adults.",
            feedback: "Students spend more time reading social media than working professionals and retired adults.",
            category: "Use comparisons",
        },
        {
            objective: "This text mentions that retired adults spend more time reading books than students and working professionals.",
            feedback: "Retired adults spend more time reading books than students and working professionals.",
            category: "Use comparisons",
        },
        {
            objective: "This text mentions that working professionals spend more time reading newspapers than students and retired adults.",
            feedback: "Working professionals spend more time reading newspapers than students and retired adults.",
            category: "Use comparisons",
        },
        {
            objective: "This text mentions that students spend less time reading newspapers than working professionals and retired adults.",
            feedback: "Students spend less time reading newspapers than working professionals and retired adults.",
            category: "Use comparisons",
        },
        {
            objective: "This text mentions that retired adults spend less time reading social media than students and working professionals.",
            feedback: "Retired adults spend less time reading social media than students and working professionals.",
            category: "Use comparisons",
        },
    ],
    13:[
        {
            objective: "This text mentions walking surfaces and their impact on health.",
            feedback: "Walking surfaces and their impact on health.",
            category: "Topic",
        },
        {
            objective: "This text mentions study conducted on people over sixty years old.",
            feedback: "Study conducted on people over sixty years old.",
            category: "Key points",
        },
        {
            objective: "This text mentions comparison of walking on flat surfaces vs. cobblestone-like surfaces.",
            feedback: "Comparison of walking on flat surfaces vs. cobblestone-like surfaces.",
            category: "Key points",
        },
        {
            objective: "This text mentions health benefits observed in participants walking on stones.",
            feedback: "Health benefits observed in participants walking on stones.",
            category: "Key points",
        },
        {
            objective: "This text mentions potential reasons for the observed health benefits.",
            feedback: "Potential reasons for the observed health benefits.",
            category: "Key points",
        },
        {
            objective: "This text mentions scientists in the US who conducted a study.",
            feedback: "Scientists in the US who conducted a study.",
            category: "Characters and actions",
        },
        {
            objective: "This text mentions participants who walked on either flat surfaces or cobblestone surfaces.",
            feedback: "Participants who walked on either flat surfaces or cobblestone surfaces.",
            category: "Characters and actions",
        },
        {
            objective: "This text mentions that cobblestone group showed improved fitness and physical control.",
            feedback: "Cobblestone group showed improved fitness and physical control.",
            category: "Characters and actions",
        },
        {
            objective: "This text mentions that people in China have long believed in the health benefits of stone walking.",
            feedback: "People in China have long believed in the health benefits of stone walking.",
            category: "Characters and actions",
        },
        {
            objective: "This text mentions that walking on uneven surfaces may require the body to work harder.",
            feedback: "Walking on uneven surfaces may require the body to work harder.",
            category: "Conclusions and relationships",
        },
        {
            objective: "This text mentions that different foot areas are stimulated by walking on stones.",
            feedback: "Different foot areas are stimulated by walking on stones.",
            category: "Conclusions and relationships",
        },
        {
            objective: "This text mentions that stone-walking may offer unique health advantages compared to flat walking.",
            feedback: "Stone-walking may offer unique health advantages compared to flat walking.",
            category: "Conclusions and relationships",
        },
        {
            objective: "This text mentions that stone carpets provide an accessible option for gaining health benefits.",
            feedback: "Stone carpets provide an accessible option for gaining health benefits.",
            category: "Conclusions and relationships",
        },
    ],
    14:[
        {
            objective: "This text mentions the scientific process of creating new recipes.",
            feedback: "The scientific process of creating new recipes.",
            category: "Topic",
        },
        {
            objective: "This text mentions importance of precision when developing recipes.",
            feedback: "Importance of precision when developing recipes.",
            category: "Key points",
        },
        {
            objective: "This text mentions use of a test kitchen for recipe development.",
            feedback: "Use of a test kitchen for recipe development.",
            category: "Key points",
        },
        {
            objective: "This text mentions repeated trials to perfect recipies.",
            feedback: "Repeated trials to perfect recipes.",
            category: "Key points",
        },
        {
            objective: "This text mentions feedback from non-chefs to refine recipes for clarity and accuracy.",
            feedback: "Feedback from non-chefs to refine recipes for clarity and accuracy.",
            category: "Key points",
        },
        {
            objective: "This text mentions that chefs aim to develop recipes for cookbooks or specific purposes.",
            feedback: "Chefs aim to develop recipes for cookbooks or specific purposes.",
            category: "Characters and actions",
        },
        {
            objective: "This text mentions that chefs work in a test kitchen to develop recipes with precision.",
            feedback: "Chefs work in a test kitchen to develop recipes with precision.",
            category: "Characters and actions",
        },
        {
            objective: "This text mentions that recipes are tested by competent non-chefs at home.",
            feedback: "Recipes are tested by competent non-chefs at home.",
            category: "Characters and actions",
        },
        {
            objective: "This text mentions that chefs receive feedback to improve the recipe's clarity and outcome.",
            feedback: "Chefs receive feedback to improve the recipe's clarity and outcome.",
            category: "Characters and actions",
        },
        {
            objective: "This text mentions that recipe creation requires a scientific approach.",
            feedback: "Recipe creation requires a scientific approach.",
            category: "Conclusions and relationships",
        },
        {
            objective: "This text mentions that precision in the process ensures recipes are replicable by others.",
            feedback: "Precision in the process ensures recipes are replicable by others.",
            category: "Conclusions and relationships",
        },
        {
            objective: "This text mentions that feedback from non-chefs helps improve recipe accessibility.",
            feedback: "Feedback from non-chefs helps improve recipe accessibility.",
            category: "Conclusions and relationships",
        },
        {
            objective: "This text mentions that recipe development can limit chefs' creativity.",
            feedback: "Recipe development can limit chefs' creativity.",
            category: "Conclusions and relationships",
        },
    ],
    15:[
        {
            objective: "This text mentions the problem of orbital debris, also known as space junk.",
            feedback: "The problem of orbital debris, also known as space junk.",
            category: "Topic"
        },
        {
            objective: "This text mentions that Earth orbit ranges from 160 to 2000 kilometers from Earth.",
            feedback: "Earth orbit ranges from 160 to 2000 kilometers from Earth.",
            category: "Key points"
        },
        {
            objective: "This text mentions that Earth orbit become a space rubbish dump.",
            feedback: "Earth orbit has become a space rubbish dump.",
            category: "Key points"
        },
        {
            objective: "This text mentions that millions of pieces of space junk are in orbit.",
            feedback: "Millions of pieces of space junk are in orbit.",
            category: "Key points"
        },
        {
            objective: "This text mentions that space junk travels at speeds of 30,000 kilometers per hour.",
            feedback: "Space junk travels at speeds of 30,000 kilometers per hour.",
            category: "Key points"
        },
        {
            objective: "This text mentions that clearing up space debris is technically challenging.",
            feedback: "Clearing up space debris is technically challenging.",
            category: "Key points"
        },
        {
            objective: "This text mentions that space junk consists of broken satellites.",
            feedback: "Space junk consists of broken satellites.",
            category: "Characters and actions"
        },
        {
            objective: "This text mentions that space junk consists of spacecraft parts.",
            feedback: "Space junk consists of spacecraft parts.",
            category: "Characters and actions"
        },
        {
            objective: "This text mentions that space junk consists of rockets.",
            feedback: "Space junk consists of rockets.",
            category: "Characters and actions"
        },
        {
            objective: "This text mentions that space junk travels at extremely high speeds.",
            feedback: "Space junk travels at extremely high speeds.",
            category: "Characters and actions"
        },
        {
            objective: "This text mentions that determining responsibility for cleaning up space junk is a significant issue.",
            feedback: "Determining responsibility for cleaning up space junk is a significant issue.",
            category: "Characters and actions"
        },
        {
            objective: "This text mentions that deciding what to do with the collected debris is a significant issue.",
            feedback: "Deciding what to do with the collected debris is a significant issue.",
            category: "Characters and actions"
        },
        {
            objective: "This text mentions that high speed of space junk poses a risk to safety on Earth.",
            feedback: "The high speed of space junk poses a risk to safety on Earth.",
            category: "Conclusions and relationships"
        },
        {
            objective: "This text mentions that high speed of space junk poses a risk to future space missions.",
            feedback: "The high speed of space junk poses a risk to future space missions.",
            category: "Conclusions and relationships"
        },
        {
            objective: "This text mentions that the problem of space junk is an international challenge.",
            feedback: "The problem of space junk is an international challenge.",
            category: "Conclusions and relationships"
        },
        {
            objective: "This text mentions that the problem of space junk requires global cooperation.",
            feedback: "The problem of space junk requires global cooperation.",
            category: "Conclusions and relationships"
        },
        {
            objective: "This text mentions that all nations involved in space travel share responsibility for addressing issue of space junk.",
            feedback: "All nations involved in space travel share responsibility for addressing the issue of space junk.",
            category: "Conclusions and relationships"
        },
    ],
    16:[
        {
            objective: "This text describes the process of seismic communication in elephants.",
            feedback: "The process of seismic communication in elephants.",
            category: "Topic",
        },
        {
            objective: "This text mentions challenges in studying seismic communication.",
            feedback: "Challenges in studying seismic communication.",
            category: "Key points",
        },
        {
            objective: "This text mentions creation of an ethogram to document elephant behaviors.",
            feedback: "Creation of an ethogram to document elephant behaviors.",
            category: "Key points",
        },
        {
            objective: "This text mentions simplification of familiar elephant behaviors.",
            feedback: "Simplification of familiar elephant behaviors.",
            category: "Key points",
        },
        {
            objective: "This text mentions detailed depiction for complex elephant behavior patterns.",
            feedback: "Detailed depiction for complex elephant behavior patterns.",
            category: "Key points",
        },
        {
            objective: "This text mentions that researchers study seismic communication in elephants.",
            feedback: "Researchers study seismic communication in elephants.",
            category: "Characters and actions",
        },
        {
            objective: "This text mentions that researchers create an ethogram for behaviors in signal detection.",
            feedback: "Researchers create an ethogram for behaviors in signal detection.",
            category: "Characters and actions",
        },
        {
            objective: "This text mentions that elephants engage in specific behaviors when responding to signals.",
            feedback: "Elephants engage in specific behaviors when responding to signals.",
            category: "Characters and actions",
        },
        {
            objective: "This text mentions that seismic and acoustic waves are present during elephant communication.",
            feedback: "Seismic and acoustic waves are present during elephant communication.",
            category: "Characters and actions",
        },
        {
            objective: "This text mentions that ethograms help researchers organize and understand complex behaviors.",
            feedback: "Ethograms help researchers organize and understand complex behaviors.",
            category: "Conclusions and relationships",
        },
        {
            objective: "This text mentions that known behaviors may not require detailed explanation.",
            feedback: "Known behaviors may not require detailed explanation.",
            category: "Conclusions and relationships",
        },
        {
            objective: "This text mentions that diagrams enhance understanding of multiple behaviors.",
            feedback: "Diagrams enhance understanding of multiple behaviors.",
            category: "Conclusions and relationships",
        },
        {
            objective: "This text mentions that elephants use both seismic and acoustic cues in their communication.",
            feedback: "Elephants use both seismic and acoustic cues in their communication.",
            category: "Conclusions and relationships",
        },
    ]
}