export const getBreakdownColor = (label) => {

    const root = document.documentElement;      
    const colorLow = getComputedStyle(root).getPropertyValue('--score-low');   
    const colorMid = getComputedStyle(root).getPropertyValue('--score-mid');   
    const colorHigh = getComputedStyle(root).getPropertyValue('--score-high'); 


    if(label === "Low"){
        return colorLow
    }else if(label === "Mid"){
        return colorMid
    }else if(label === "High"){
        return colorHigh
    }else{
        return colorLow
    }
}