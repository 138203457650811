import React, { createContext, useState, useContext, useEffect } from 'react';

const LoadingTimeContext = createContext();

export const LoadingTimeProvider = ({ children }) => {
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  // Derived state for isLoading
  const isLoading = startTime !== null && endTime === null;

  const startLoading = () => {
    setStartTime(performance.now());
    setEndTime(null); // Reset endTime to ensure `isLoading` reflects correctly
  };

  const endLoading = () => {
    setEndTime(performance.now());
  };

  const resetTimes = () => {
    // console.log('reset times')
    setStartTime(null);
    setEndTime(null);
  };

  useEffect(() => {
    if(startTime){
      // console.log(`Start time set: ${startTime.toFixed(2)}ms`);
    }
  }, [startTime]);

  useEffect(() => {
    if(endTime){
    // console.log(`End time set: ${endTime.toFixed(2)}ms`);
    }
  }, [endTime]);

  useEffect(() => {
    // console.log(`isLoading: ${isLoading}`);
  }, [isLoading]);


  return (
    <LoadingTimeContext.Provider value={{ startLoading, endLoading, resetTimes, startTime, endTime, isLoading}}>
      {children}
    </LoadingTimeContext.Provider>
  );
};

export const useLoadingTime = () => useContext(LoadingTimeContext);
