import {useEffect, useState} from 'react'
import { cx, css } from '@emotion/css'
import styled from '@emotion/styled'
import {Page, PrimaryButton} from "../global_styles/global_styles"
import {questions} from "../data/survey_data"
import { saveSurvey } from '../util/saveSurvey'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setUserData } from '../redux/profileSlice'

import radioOn from "../images/radio-on.svg"
import radioOff from "../images/radio-off.svg"
import checkboxOn from "../images/checkbox-on.svg"
import checkboxOff from "../images/checkbox-off.svg"

function Survey(){

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [currentQuestion, setCurrentQuestion] = useState(1)
    const [nextDisabled, setNextDisabled] = useState(true)
    const [answer, setAnswer] = useState(null)
    const [feedback, setFeedback] = useState("")
    const [surveyEnded, setSurveyEnded] = useState(false)
    const [surveyResponse, setSurveyResponse] = useState({})

    const handleNext = () => {
        if(currentQuestion === questions.length){
            handleEndSurvey()
        }else{
            setCurrentQuestion(currentQuestion + 1)
            handleAnswerQuestion()
        }
    }

    const handleAnswerQuestion = () => {
        setSurveyResponse(prev=>{
            let newResponse = Object.assign({}, prev)
            newResponse[currentQuestion] = answer
            return newResponse
        })
        setAnswer(null)
        setNextDisabled(true)
    }

    const handleRadio = (e) => {
        setAnswer((prev) => {
            let newAnswer = {...prev}
            newAnswer.answer = e.target.value
            return newAnswer
        });
        //remove all checkboxs selected
       let checkboxes = document.querySelectorAll('input[type="checkbox"]')
       if(checkboxes.length > 0){
            checkboxes.forEach(checkbox=>{
                checkbox.checked = false
            })
       }
    }

    const handleCheckbox = (e) => {
        if(e.target.checked){
            setAnswer((prev)=>{
                let newAnswer = {...prev}
                if(!newAnswer.answer || !Array.isArray(newAnswer.answer)){
                    newAnswer.answer = []
                }
                newAnswer.answer.push(e.target.value)
                return newAnswer
            })
        }
        else{
            setAnswer((prev) => {
                let newAnswer = {...prev}
                 newAnswer.answer = newAnswer.answer.filter(item => item !== e.target.value);
                return newAnswer
            });
        }
        //remove all radios selected
       let radios = document.querySelectorAll('input[type="radio"]')
       if(radios.length > 0){
            radios.forEach(radio=>{
                radio.checked = false
            })
       }
    }

    const handleOtherFeedback = (e) => {
        if(e.target.value === ""){
            setAnswer(prev=>{
                let newAnswer = {...prev}
                delete newAnswer.other
                return newAnswer
            })
        }else{
            setAnswer(prev=>{
                let newAnswer = {...prev}
                newAnswer.other = e.target.value
                return newAnswer
            })
        }
        
    }

    const handleFeedback = (e) => {
        setFeedback(e.target.value)
    }

    const handleEndSurvey = () => {
        setSurveyEnded(true)
        setSurveyResponse(prev=>{
            if(feedback.length > 0){
                let newResponse = Object.assign({}, prev)
                newResponse['feedback'] = feedback
                return newResponse
            }else{
                return {...prev}
            }
        })
    }

    useEffect(()=>{
        let startSurvey = questions.reduce((result, item) => {
            result[item.id] = {}
            return result;
        }, {});
        setSurveyResponse(startSurvey)
    },[])

    useEffect(()=>{
        if(answer){
            setNextDisabled(false)
        }
    },[answer])

    useEffect(()=>{
        if(surveyEnded){
            // console.log(surveyResponse)
            saveSurvey({surveyResponse: surveyResponse})
            .then((response)=>response.json())
            .then(data=>{
                if(data.username){
                    // console.log(data)
                    localStorage.setItem('profile', JSON.stringify(data));
                    dispatch(setUserData(data))
                    navigate("/dashboard")
                }
            })
        }
    },[surveyResponse])


    return(
        <Page className={cx(SurveyPage)}>
            <SurveyHeader>
                <p>Your feedback is important</p>
                <p>We want to get your feedback about the scoring and feedback information you've just seen. </p>
            </SurveyHeader>
            <SurveyContainer>
                    <QuestionBody>
                        <QuestionHeader>
                            {
                                questions[currentQuestion - 1].type === 'feedback' ? 
                                <p>Optional</p>
                                :
                                <p>Question {currentQuestion} of {questions.length - 1}</p>
                            }
                        </QuestionHeader>
                        <Question>
                            {questions[currentQuestion - 1].question}
                        </Question>
                        <QuestionOptions>
                            <fieldset>
                            {
                                questions[currentQuestion - 1].options ?
                                <ul>
                                    {
                                        questions[currentQuestion - 1].options.map((option, index)=>{
                                            if(questions[currentQuestion - 1].type === "radio"){
                                                return(
                                                    <Option key={`question-${currentQuestion}-option-${index}`} >
                                                        <input 
                                                            type="radio" 
                                                            id={`question-${currentQuestion}-option-${index}`} 
                                                            name={`question-${currentQuestion}`} 
                                                            value={option}
                                                            onChange={handleRadio}
                                                        />
                                                        <span>
                                                            <img src={radioOn}/>
                                                        </span>
                                                        <label 
                                                            htmlFor={`question-${currentQuestion}-option-${index}`}
                                                        >
                                                            {option}
                                                        </label>
                                                    </Option>
                                                )
                                            }else if(questions[currentQuestion - 1].type === "multiple_choice"){
                                                return(
                                                    <Option key={`question-${currentQuestion}-option-${index}`} >
                                                        <input 
                                                            type="checkbox" 
                                                            id={`question-${currentQuestion}-option-${index}`} 
                                                            name={`question-${currentQuestion}`} 
                                                            value={option}
                                                            onChange={handleCheckbox}
                                                        />
                                                        <span>
                                                            <img src={checkboxOn}/>
                                                        </span>
                                                        <label 
                                                            htmlFor={`question-${currentQuestion}-option-${index}`}
                                                        >
                                                            {option}
                                                        </label>
                                                    </Option>
                                                )
                                            }
                                        })
                                    }
                                    {
                                        questions[currentQuestion - 1].otherFeedback ?
                                        <>
                                            <p>Other</p>
                                            <QuestionInput
                                                placeholder="Start typing here"
                                                onChange={handleOtherFeedback}
                                            />
                                        </>
                                        : null
                                    }
                                    {
                                        questions[currentQuestion - 1].moreOptions ? 
                                        <>
                                            <Divider>Or</Divider>
                                            {
                                                questions[currentQuestion - 1].moreOptions.map((option, index)=>{
                                                    return(
                                                        <Option key={`question-${currentQuestion}-optionmore-${index}`} >
                                                            <input 
                                                                type="radio" 
                                                                id={`question-${currentQuestion}-optionmore-${index}`} 
                                                                name={`question-${currentQuestion}`} 
                                                                value={option}
                                                                onChange={handleRadio}
                                                            />
                                                            <span>
                                                                <img src={radioOn}/>
                                                            </span>
                                                            <label 
                                                                htmlFor={`question-${currentQuestion}-optionmore-${index}`}
                                                            >
                                                                {option}
                                                            </label>
                                                        </Option>
                                                    )
                                                })
                                            }
                                        </> 
                                        : null
                                    }
                                </ul>
                                : null
                            }
                            </fieldset>
                        </QuestionOptions>
                        {
                            questions[currentQuestion -1].type === "feedback" ?
                            <QuestionInput
                                placeholder="Start typing here"
                                onInput={handleFeedback}
                                value={feedback}
                            />
                            : null
                        }
                        
                    </QuestionBody>
                    <button 
                        className={PrimaryButton}
                        disabled={currentQuestion === questions.length ? false : nextDisabled}
                        onClick={handleNext}
                    > 
                        {currentQuestion === questions.length ? "Finish" : "Next"}
                    </button>
            </SurveyContainer>
        </Page>
    )
}

export default Survey

const SurveyPage = css`
    background: var(--color-fill-section-secondary, #FAFAFA) !important;
`

const SurveyContainer = styled.div`
    margin-left: -16px;
    width: calc(100% + 32px);
    padding: 0 16px;
    padding-bottom:16px;
    min-height: calc(100% - 97px);
    background-color:white;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
`

const SurveyHeader = styled.div`
    p{
        font-size: 16px;
        line-height: 24px;
        margin:0;
        text-align:center;
    }
    p:first-of-type{
        color: var(--color-text-primary);
        font-weight: 600;
        margin-bottom:10px;
        margin-top:16px;
    }
    p:last-of-type{
        color: var(--color-text-tertiary);
        font-weight: 400;
        margin-bottom:16px;
    }
`

const QuestionBody = styled.div`
    flex:1;
`

const QuestionHeader = styled.div`
    p{
        color: var(--color-text-tertiary);
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin:16px 0;
    }
`

const Question = styled.p`
    color: #000;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin:16px 0;
`

const QuestionOptions = styled.div`
    fieldset{
        border:none;
        margin:0;
        padding:0;
        margin-top:8px;
    }
`

const Option = styled.div`
    position:relative;
    height: 22px;
    display: flex;
    align-items: center;
    margin-bottom:24px;
    gap:12px;
    cursor:pointer;
    input{
        opacity: 0;
        height: 20px;
        width: 20px;
        cursor: pointer;
        z-index: 1;
        left: 0;
        position: absolute;
        top: 0;
        &[type=radio]:checked~span img, &[type=checkbox]:checked~span img {
            opacity: 1;
        }
        &[type=checkbox]~span{
            border-radius: 4px;
        }
        &[type=radio]~span{
            border-radius: 20px;
        }
    }
    input~span{
        display:block;
        height: 20px;
        line-height: 1.5;
        text-align: center;
        width: 20px;
        border: 1px solid #8D9496;
        z-index: 0;
        background: transparent;
        position:relative;
        img{
            opacity: 0;
            height:25px;
            width: 25px;
            position: absolute;
            top: -3.5px;
            left: -3.5px;
        }
    }
    label{
        cursor:pointer;
    }
`

const Divider = styled.span`
    display:block;
    margin: 40px 0;
`

const QuestionInput = styled.textarea`
    border-radius: 8px;
    border: 1px solid var(--color-stroke-interactive-default);
    width:100%;
    height:86px;
    padding:8px;
    resize:none;
    font-family: "Open Sans", sans-serif;
    font-size:16px;
    line-height: 24px;
`