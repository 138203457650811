import {useState, useEffect, useRef} from 'react'
import { cx, css } from '@emotion/css'
import styled from '@emotion/styled'
import {getTypeProperName} from "../util/getTypeProperName.js"
import { PrimaryButton, TaskContainer, ShortInstructions} from "../global_styles/global_styles.js"
import ListenTimer from "../components/shared/ListenTimer.jsx"
import parse from "html-react-parser";
import mixpanel from "mixpanel-browser";

//images
import headphones from "../images/headphones.svg"
import play from "../images/play.svg"
import listenFill from "../images/listen_fill.svg"
import listenBackground from "../images/listen_background.svg"
import listenWhite from "../images/listen_white.svg"

function Listen({questionData, typeData, handleContinue}){

    const [totalTime, setTotalTime] = useState(0)
    const [currentTime, setCurrentTime] = useState(0)
    const [playDisabled, setPlayDisabled] = useState(false)
    const [playHidden, setPlayHidden] = useState(false)
    const [audioPlaying, setAudioPlaying] = useState(false)
    const [shortInstructions, setShortInstructions] = useState(``);

    const audioRef = useRef(null)

    const getShortInstructions = () => {
        let html = `<div className=${css(ShortInstructions)}>
                        <div>
                            <img src="${headphones}"/>
                            ${typeData.shortInstructions.listen.main}
                        </div>
                        ${typeData.shortInstructions.listen.sub}
                    </div>`
        setShortInstructions(html)
    }

    const handlePlayAudio = () => {
        audioRef.current.play()
        setAudioPlaying(true)
        setPlayDisabled(true)
        setPlayHidden(true)
    }

    const handleEnded = () => {
        //wait 2 seconds before moving to Task.jsx and starting the recording
        setTimeout(()=>{
            handleContinue()
        },2000)
    }

    const handleGetDuration = () => {
        setTotalTime(audioRef.current.duration)
    }

    const handleTimeUpdate = () => {
        setCurrentTime(audioRef.current.currentTime);
    }

    useEffect(()=>{
        if(typeData.shortInstructions && typeData.shortInstructions.listen){
            getShortInstructions()
        }
    },[typeData])

    return (
        <>
            <h2>{getTypeProperName(questionData.type)}</h2>
            <TaskContainer className={questionData.type != 'retell_lecture' ? CenterContainer : ''}>
                {
                    shortInstructions != '' ?
                        <>{parse(shortInstructions)}</>
                        : null
                }
                {
                    questionData.type === 'repeat_sentence' || questionData.type === 'short_question' ?
                        <>
                            <ListenText>
                                <img src={headphones}/>
                                <b>Listen</b>
                            </ListenText>
                            <ListenAnimation>
                                <Mask totalTime={totalTime} currentTime={currentTime}>
                                    <img src={listenFill}/>
                                </Mask>
                                <ListenBackground src={listenBackground}/>
                            </ListenAnimation>
                        </>
                        : null
                }
                {
                    questionData.type === 'retell_lecture' ?
                        <ListenImage>
                            <ImageContainer>
                                {
                                    questionData.type === 'retell_lecture' && playHidden != true ?
                                        <button
                                            className={cx(PrimaryButton, AltPlacementButton)}
                                            onClick={() => {
                                                mixpanel.track('Audio Button Clicked', {
                                                    interactionType: 'Click',
                                                });
                                                handlePlayAudio();
                                            }}
                                            onTouchStart={() => {
                                                mixpanel.track('Audio Button Clicked', {
                                                    interactionType: 'Touch',
                                                });
                                                handlePlayAudio();
                                            }}
                                        >
                                            <img src={play} alt="Play Audio"/>
                                            Play audio
                                        </button>

                                        : null
                                }
                                {
                                    (questionData.question.image && !audioPlaying) || !questionData.question.image ?
                                        <ListenWhite src={listenWhite}/>
                                        : null
                                }
                                {
                                    questionData.question.image ?
                                        <DataImage audioPlaying={audioPlaying}>
                                            <img src={questionData.question.image}/>
                                        </DataImage>
                                        :
                                        <DataImagePlaceholder/>
                                }
                                {
                                    totalTime > 0 ?
                                        <ListenTimer
                                            value={Math.round(totalTime)}
                                            audioPlaying={audioPlaying}/>
                                        : null
                                }

                            </ImageContainer>
                        </ListenImage>
                        : null
                }
            </TaskContainer>
            <audio
                ref={audioRef}
                onEnded={handleEnded}
                onLoadedMetadata={handleGetDuration}
                onTimeUpdate={handleTimeUpdate}
            >
                <source src={questionData.question.audio} type="audio/mpeg"/>
            </audio>
            {
                questionData.type != 'retell_lecture' ?
                    <button
                        className={css(PrimaryButton)}
                        onClick={() => {
                            mixpanel.track('Play Audio Button Clicked', {
                                buttonState: playDisabled ? 'Disabled' : 'Enabled', // Logs if the button was disabled or enabled
                                action: playDisabled ? 'Attempted to play while disabled' : 'Played audio', // Describes the action
                            });
                            if (!playDisabled) {
                                handlePlayAudio(); // Calls the original handler only if the button is enabled
                            }
                        }}
                        disabled={playDisabled}
                    >
                        {
                            !playDisabled ?
                                <>
                                    <img src={play} alt="Play audio"/>
                                    Play audio
                                </>
                                :
                                <>Playing audio</>
                        }
                    </button>

                    : null
            }
            {/* <button
                onClick={() => {
                    mixpanel.track('Skip Audio Button Clicked', {
                        buttonLabel: 'temporary: skip audio', // Logs the button label
                        action: 'Skipped audio playback',   // Describes the action
                    });
                    handleContinue(); // Calls the original handler
                }}
            >
                temporary: skip audio
            </button> */}

        </>
    )
}

export default Listen

const CenterContainer = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const ListenText = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
    width: 100%;
`

const ListenWhite = styled.img`
    position:absolute;
    width: 197px;
    height: 49px;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    z-index:1;
`

const ListenImage = styled.div`
    width:100%;
    height:300px;
    border-radius: 8px;
    background: var(--color-fill-container-tertiary);
    padding:16px;
    margin-top:40px;
`

const ImageContainer = styled.div`
    width:100%;
    height:233px;
    position:relative;
`

const DataImage = styled.div`
    width:100%;
    height:233px;
    position:relative;
    margin-bottom:8px;
    img{
        width:100%;
        height:233px;
        object-fit:cover;
    }
    &:before{
        display:block;
        content:'';
        width:100%;
        height:100%;
        background-color:black;
        opacity:${props=> props.audioPlaying ? '0%' : '50%'};
        position:absolute;
        top:0;
        left:0;
    }
`

const DataImagePlaceholder = styled.div`
    width:100%;
    height:233px;
    position:relative;
    margin-bottom:8px;
`

const AltPlacementButton = css`
    position:absolute;
    width: 153px;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    z-index:2;
`

const ListenAnimation = styled.div`
    width:285px;
    margin:0 auto;
    margin-top:12px;
    position:relative;
    height:80px;
`

const Mask = styled.div`
    width: ${props => (props.currentTime * 100) / props.totalTime}%;
    transform:width:100ms ease;
    overflow:hidden;
    position:absolute;
    top:0;
    left:0;
    z-index:1001;
`

const ListenBackground = styled.img`
    position:absolute;
    top:0;
    left:0;
    z-index:1000;
`