import {useEffect, useState} from 'react'
import { cx, css } from '@emotion/css'
import styled from '@emotion/styled'
import {PrimaryButton, TaskContainer} from "../global_styles/global_styles"
import parse from "html-react-parser";
import {getTypeProperName} from "../util/getTypeProperName.js"
import { getTimeString } from '../util/getTimeString.js';
import mixpanel from "mixpanel-browser";

//images
import person1 from "../images/person1.svg"
import person2 from "../images/person2.svg"
import person3 from "../images/person3.svg"
import clock from "../images/clock.svg"

function Instructions({questionData, handleStart, typeData}) {

    const getIllustration = () => {
        let illustration
        if(questionData.type === "read_aloud"){
            illustration = person1
        }
        else if(questionData.type === "describe_image"){
            illustration = person2
        }
        else{
            illustration = person3
        }
        return illustration
    }

    useEffect(()=>{
        navigator.permissions.query({ name: 'microphone' }).then((permissionStatus) => {
            // console.log('Microphone permission state:', permissionStatus.state);
          
            if (permissionStatus.state === 'prompt') {
              // To prompt the user for permission, you must still attempt to access the microphone
              navigator.mediaDevices.getUserMedia({ audio: true })
                .then((stream) => {
                //   console.log('User granted microphone access');
                  // Stop the stream immediately if you don't need it
                  stream.getTracks().forEach((track) => track.stop());
                })
                .catch((error) => {
                //   console.log('Microphone access denied:', error);
                });
            }
          });          
    },[])

    return(
        <>
            {
                questionData ? <>
                        <TaskContainer>
                            <img src={getIllustration()}/>
                            <InstructionsH2>{getTypeProperName(questionData.type)}</InstructionsH2>
                            <TaskTime>
                                <img src={clock}/>
                                <p>{getTimeString(questionData.type)}</p>
                            </TaskTime>
                            <InstructionsSubtitle>Instructions</InstructionsSubtitle>
                            <InstructionsContainer>
                                {
                                    typeData ?
                                        <>{parse(typeData.instructions)}</>
                                        : null
                                }
                            </InstructionsContainer>
                        </TaskContainer>
                        <button
                            className={css(PrimaryButton)}
                            onClick={() => {
                                mixpanel.track('Start Button Clicked', {
                                    buttonName: 'Start', // Example property to identify the button
                                    timestamp: new Date().toISOString(), // Tracks when the button was clicked
                                });
                                handleStart(); // Calls the original handler
                            }}
                        >
                            Start
                        </button>

                    </>
                    : null
            }
        </>
    )


}

export default Instructions

const InstructionsH2 = styled.h2`
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
`

const TaskTime = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 8px 0;

    p {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        color:#212427;
        margin:0;
    }
`

const InstructionsSubtitle = css`
    margin:0;
    font-size: 18px
    font-weight: 400;
    line-height: 24px;
`

const InstructionsContainer = styled.div`
    font-size: 18px
    font-weight: 400;
    line-height: 24px;
    ol li{
        margin-top: 24px;
    }
`