import { useState, useEffect } from 'react';
import { cx, css } from '@emotion/css'
import styled from '@emotion/styled'

const Timer = (props) => {
	const [resetTimer, setResetTimer] = useState(props.resetTimer);
	const [maxVal, setMaxVal] = useState(parseInt(props.value));
	const [timerVal, setTimerVal] = useState(0);
	const [remainingTime, setRemainingTime] = useState(parseInt(props.value));
	let interval;

	/* Funtion to convert seconds into minute and seconds time stamp */
	function secondsToTimestamp(seconds) {
		const remainingMins = Math.floor(seconds / 60);
		const remainingSecs = Math.floor(seconds % 60);

		const formattedMinutes = remainingMins.toString().padStart(2, '0');
		const formattedSeconds = remainingSecs.toString().padStart(2, '0');

		return `${formattedMinutes}:${formattedSeconds}`;
	}

	const getTime = () => {
        if (timerVal < maxVal) {
            setTimerVal((timerVal) => timerVal + 1);
            setRemainingTime((remainingTime) => remainingTime - 1);
        } else {
            clearInterval(interval);
        }
	};

	useEffect(() => {
        interval = setInterval(getTime, 1000);
		return () => clearInterval(interval);
	}, [resetTimer, timerVal]);

    //reset timer
    useEffect(()=>{
        setResetTimer(props.resetTimer)
    },[props.resetTimer])

    useEffect(()=>{
        if(resetTimer === true){
            clearInterval(interval);
            setMaxVal(parseInt(props.value));
            setTimerVal(0);
            setRemainingTime(parseInt(props.value));
            props.resetTimerCallback()
        }
    },[resetTimer])

    //signal Task.jsx when the timer is up
     useEffect(()=>{
        if(remainingTime === 0){
            props.handleTimerEnd()
        }
    },[remainingTime])

	return (
		<TimerContainer>
            {
                remainingTime > 0 ?
                <progress value={timerVal} max={maxVal}>
                    {maxVal}
                </progress>
                : null
            }
			
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='18'
				height='18'
				viewBox='0 0 18 18'
				fill='none'
			>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0ZM9 1.75C4.99594 1.75 1.75 4.99594 1.75 9C1.75 13.0041 4.99594 16.25 9 16.25C13.0041 16.25 16.25 13.0041 16.25 9C16.25 4.99594 13.0041 1.75 9 1.75ZM9 4C9.55229 4 10 4.44772 10 5V9.59116L11.7082 11.2994C12.0973 11.6884 12.0973 12.3192 11.7082 12.7082C11.3192 13.0973 10.6884 13.0973 10.2994 12.7082L8.35735 10.7662C8.13888 10.5827 8 10.3076 8 10V5C8 4.44772 8.44771 4 9 4Z'
					fill='#101112'
				/>
			</svg>

            {
                remainingTime > 0 ?
                <span className={`time-remaining ${remainingTime < 5 ? 'warning' : ''}`}>
                    {secondsToTimestamp(remainingTime)}
                </span>
                : 
                <TimesUpText>The time is up</TimesUpText>
            }
			
		</TimerContainer>
	);
};
export default Timer;

const TimerContainer = styled.div`
    padding: 6px;
    display: flex;
    align-items: center;
    gap: 4px;
    color: #101112;
    margin-top:24px;
    
    progress[value] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 100%;
        height: 8px;
        position: relative;
    
        &::-webkit-progress-bar {
            background-color: #F0F6FF;
            border-radius: 4px;
            box-shadow: none;
        }
    
        &::-webkit-progress-value {
            background: #101112;
            border-radius: 4px;
            box-shadow: none;
        }
    
        &::-moz-progress-bar {
            background: #101112;
            border-radius: 4px; 
        }
    }

    .time-remaining {
        padding: 0 4px;

        &.warning {
            color: #B65116;
        }
    }
`

const TimesUpText = styled.p`
    color: #505759;
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
    margin:0;
`


