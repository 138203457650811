import {useEffect, useState} from 'react'
import styled from '@emotion/styled'
import { cx, css } from '@emotion/css'
import { Modal, Overlay, PrimaryButton, SecondaryButton } from "../global_styles/global_styles"
import FocusTrap from "focus-trap-react"
import {saveCustomerFeedback} from "../util/saveCustomerFeedback"

function FeedbackModal({toggleFeedbackModal}){

    const [feedback, setFeedback] = useState(null)

    const handleSubmit = () => {
        toggleFeedbackModal()
        if(feedback && feedback.length > 0){
            saveCustomerFeedback({customerFeedback: feedback})
        }
    }

    return(
        <>
        <FocusTrap>
            <div className={Modal}>
                <ModalHeader>Give us feedback</ModalHeader>
                <ModalSubtitle>What is your experience of our product?</ModalSubtitle>
                <Input
                    placeholder="Start typing here"
                    onChange={(e)=>{setFeedback(e.target.value)}}
                />
                <ModalButtons>
                    <button
                        className={css(LinkButton)}
                        onClick={()=>{toggleFeedbackModal()}}
                    >
                        Exit
                    </button>
                    <button
                        className={css(PrimaryButton)}
                        onClick={handleSubmit}
                    >
                        Submit
                    </button>
                </ModalButtons>
                
            </div>
        </FocusTrap>
        <div className={Overlay}></div>
        </>
    )
}

export default FeedbackModal

const ModalHeader = styled.h1`
    color: #000;
    font-size: 20px;
    font-weight: 400;
    text-align:left;
    width:100%;
`

const ModalSubtitle = styled.h2`
    color: #000;
    font-size: 16px;
    font-weight: 400;
    margin: 11px 0;
    text-align:left;
    width:100%;
`

const Input = styled.textarea`
    border-radius: 8px;
    border: 1px solid var(--color-stroke-interactive-default);
    width:100%;
    height:137px;
    padding:8px;
    resize:none;
    font-family: "Open Sans", sans-serif;
    font-size:16px;
    line-height: 24px;
`

const ModalButtons = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    width:100%;
    margin-top:19px;
    button{
        flex: 1
    }
`

const LinkButton = css`
    color: var(--color-text-interactive-brand-default);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    width: 107px;
    display:flex;
    align-items:center;
    justify-content:center;
  `