export const feedback_strings = {
    content_overall:{
        read_aloud: {
            0: "There's room to grow here! Focus on reading all the words in the text carefully.", //low
            1: "Good effort! You read the text well, though you may have missed some words.", //mid
            2: "There's room to grow here! Focus on reading all the words in the text carefully." //high
        },
        repeat_sentence:{
            0: "There's room to grow here! Focus on repeating as many of the words as you can from the sentence.", //low
            1: "Good effort! You repeated many of the words, though there's room to work on capturing the full sentence.", //mid
            2: "Nice work! You repeated all or nearly all the words correctly, showing strong listening and recall skills." //high
        },
        describe_image:{
            0: "There's room to grow here! Focus on describing the key features of the image, such as axes, labels, numbers, and relationships.", //low
            1: "Good effort! You described the image well, covering many key features and some comparisons.", //mid
            2: "Nice work! You provided a clear and detailed description." //high
        },
        retell_lecture:{
            0: "There's room to grow here! Focus on mentioning the main topic, key points, characters, and conclusions from the lecture.", //low
            1: "Good effort! You retold the lecture well, with room to include a bit more detail.", //mid
            2: "Nice work! You retold the lecture with strong attention to detail. " //high
        }
    },
    fluency_overall:{
        0: "Your fluency needs improvement. Work on maintaining a steady pace, reducing hesitations, and avoiding pauses or repetitions. With practice, you'll get there!", //low
        1: "Good job! Your fluency was generally good, though there were some hesitations, pauses, or repetitions, and your speed may have varied.", //mid
        2: "Nicely done! Your fluency was strong, with steady speed, minimal hesitations, and very few unexpected pauses or repetitions." //high
    },
    pronunciation_overall:{
        0: "Your pronunciation needs improvement. Focus on improving clarity and reducing mispronunciations. You've got this!", //low
        1: "Good effort! Your pronunciation was mostly clear, though there were a few mispronunciations", //mid
        2: "Great work! Your pronunciation was clear and accurate, with very few or no mispronunciations." //high
    },
    content_closed_ended:[
        {
            score: [0, 0.10],
            text: "You did not repeat the words"
        },
        {
            score: [0.10, 0.19],
            text: "You repeated back a few words"
        },
        {
            score: [0.20, 0.29],
            text: "You repeated back only a small number of words"
        },
        {
            score: [0.30, 0.39],
            text: "You repeated back a few words"
        },
        {
            score: [0.80, 0.49],
            text: "You repeated back a few words"
        },
        {
            score: [0.50, 0.59],
            text: "You repeated back some of the words"
        },
        {
            score: [0.60, 0.69],
            text: "You repeated back most of the words"
        },
        {
            score: [0.70, 0.79],
            text: "You repeated back most of the words"
        },
        {
            score: [0.80, 0.89],
            text: "You repeated back almost all of the words"
        },
        {
            score: [0.90, 0.99],
            text: "You repeated back all the words nearly perfectly"
        },
        {
            score: [1, 1],
            text: "You repeated back all the words perfectly"
        }
    ],
    category_descriptions: [
        {
            category: "Labels",
            description: "Describe the labels, this could be the axes and the variables that are being measured"
        },
        {
            category: "Key features",
            description: "State the key features in the graph, such as highest and lowest points, significant trends and any unusual patterns or outliers."
        },
        {
            category: "Use comparisons",
            description: "Compare between the variables, periods or categories highlighting any significant differences or similarities."
        },
        {
            category: "Numbers and specifics",
            description: "Describe specific numbers and percentages in the graph."
        },
        {
            category: "Topic",
            description: "The topic (main idea or subject) is mentioned"
        },
        {
            category: "Key points",
            description: "Re-tells the important points from the lecture"
        },
        {
            category: "Characters and actions",
            description: "The main items mentioned in the lecture, such as objects or people and what they do or what happens to them"
        },
        {
            category: "Conclusions and relationships",
            description: "How different characters or ideas in the lecture are connected or related to each other and how the main points are summarised"
        },
    ]
}